export default function useOCR() {
  const getVision = async (src: string) => {
    let serial: string = "";

    const localImage = (base64Data: string) => {
      return JSON.stringify({
        requests: [
          {
            image: {
              content: base64Data.replace(/data:image\/(png|jpg|jpeg|webp);base64,/, ""),
            },
            features: [
              {
                type: "TEXT_DETECTION",
              },
            ],
          },
        ],
      });
    };

    const remoteImage = (imageUri: string) => {
      return JSON.stringify({
        requests: [
          {
            image: {
              source: {
                imageUri: imageUri,
              },
            },
            features: [{ type: "TEXT_DETECTION" }],
          },
        ],
      });
    };

    const vision: Response = await fetch(
      `https://vision.googleapis.com/v1/images:annotate?key=${process.env.REACT_APP_GOOGLE_VISION_API_KEY}`,
      {
        method: "POST",
        body: src.startsWith("data:image/") ? localImage(src) : remoteImage(src),
      },
    );

    await vision.json().then((results) => {
      let output: string = "";
      if (results && results.responses[0].fullTextAnnotation) {
        output = results.responses[0].fullTextAnnotation.text;
      }

      output = output.replaceAll("O", "0");
      output = output.replaceAll("I", "1");
      const match = output.match(/Serial (.{10,12})/);
      serial = match ? match[1] : output;
    });
    return serial;
  };

  return {
    getVision,
  };
}
