import { useCallback, useMemo, useState } from "react";
import { Box, Button, IconButton, TextField, Grid, Typography, Paper, FormControl } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { DataGrid, GridColDef, GridRenderCellParams, GridSelectionModel } from "@mui/x-data-grid";
import QRScanner from "components/Scan/QRScanner";
import { ReadCompleted } from "hooks/useQrScanner";
import useScan from "hooks/useScan";
import { Inventory } from "types/inventory";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { defaultInventoryFilters } from "constants/default_inventory_filters";

const InventoryGrid = ({
  inventories,
  removeInventory,
  selectedInventories,
  setSelectedInventories,
}: {
  inventories: Inventory[];
  selectedInventories: GridSelectionModel;
  setSelectedInventories: (selectionModel: GridSelectionModel) => void;
  removeInventory: (inventoryId: number) => void;
}) => {
  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 100,
        renderCell: (params: GridRenderCellParams<Inventory>) => {
          if (params.row.pickup) {
            return <Link to={`/pickups/${params.row.pickup.uuid}/inventories/${params.row.id}`}>{params.row.id}</Link>;
          } else {
            return <div>{params.row.id}</div>;
          }
        },
      },
      {
        field: "pickupsId",
        headerName: "Pickup",
        width: 100,
        renderCell: (params: GridRenderCellParams<Inventory>) => {
          if (params.row.pickup) {
            return (
              <Link to={`/pickups/${params.row.pickup.uuid}${defaultInventoryFilters}`}>{params.row.pickup.id}</Link>
            );
          } else {
            return <div> --- </div>;
          }
        },
      },
      { field: "lineId", headerName: "Line ID", width: 125 },
      { field: "sku", headerName: "SKU", width: 150 },
      { field: "serialNumber", headerName: "Serial Number", width: 200 },
      { field: "manufacturer", headerName: "Brand", width: 150 },
      { field: "deviceType", headerName: "Type", width: 175 },
      { field: "warehouseLocation", headerName: "Location", width: 200 },
      {
        field: "actions",
        headerName: "Actions",
        renderCell: (params: GridRenderCellParams<Inventory>) => {
          return (
            <IconButton onClick={() => removeInventory(params.row.id)}>
              <DeleteIcon />
            </IconButton>
          );
        },
      },
    ],
    [removeInventory],
  );
  return (
    <DataGrid
      checkboxSelection
      rows={inventories}
      columns={columns}
      selectionModel={selectedInventories}
      onSelectionModelChange={setSelectedInventories}
    />
  );
};

export default function OutboundPage() {
  const {
    addInventory,
    removeInventory,
    inventories,
    selectedInventories,
    setSelectedInventories,
    invoiceInventory,
    handleExScanInput,
    getInventoryByWarehouseLocation,
  } = useScan();

  const [showScan, setShowScan] = useState(false);

  const handleScannedQR = useCallback<ReadCompleted>(
    (inventoryId) => {
      if (inventoryId) {
        addInventory(inventoryId);
      }
      setShowScan(false);
    },
    [addInventory],
  );

  const handleClick = () => {
    invoiceInventory();
  };

  return (
    <Box>
      <Paper variant="outlined">
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          sx={{ p: "10px", pr: "10px" }}
        >
          <Grid>
            <Typography variant={"h6"}>Outbound Inventory</Typography>
          </Grid>
          <Grid>
            <FormControl>
              <Formik
                initialValues={{ inventoryId: "" }}
                onSubmit={async (values, { resetForm }) => {
                  await addInventory(values.inventoryId);
                  resetForm();
                }}
              >
                {({ handleSubmit, getFieldProps }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      autoFocus
                      autoComplete="off"
                      label="Inventory ID"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("inventoryId")}
                      onChange={(event) => {
                        handleExScanInput(event, "inventory_id", getFieldProps("inventoryId").onChange);
                      }}
                    />
                  </form>
                )}
              </Formik>
            </FormControl>
            <FormControl sx={{ paddingLeft: "10px" }}>
              <Formik
                initialValues={{ warehouseLocation: "" }}
                onSubmit={async (values, { resetForm }) => {
                  await getInventoryByWarehouseLocation(values.warehouseLocation);
                  resetForm();
                }}
              >
                {({ handleSubmit, getFieldProps }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      autoComplete="off"
                      label="Warehouse Location"
                      variant="outlined"
                      size="small"
                      {...getFieldProps("warehouseLocation")}
                      onChange={(event) => {
                        handleExScanInput(event, "warehouse_location", getFieldProps("warehouseLocation").onChange);
                      }}
                    />
                  </form>
                )}
              </Formik>
            </FormControl>
            <Button
              startIcon={<CheckIcon />}
              onClick={handleClick}
              sx={{ p: "10px", height: "37.5px", ml: "10px" }}
              variant="contained"
              type="submit"
              size="small"
              disabled={selectedInventories.length === 0}
            >
              Mark as Invoiced
            </Button>
          </Grid>
        </Grid>
        <Box sx={{ width: "400px" }}>
          {showScan && (
            <QRScanner open={showScan} handleClose={() => setShowScan(false)} readCompleted={handleScannedQR} />
          )}
        </Box>

        <Box sx={{ height: "500px", m: "10px" }}>
          <InventoryGrid
            removeInventory={removeInventory}
            inventories={[...inventories].reverse()}
            selectedInventories={selectedInventories}
            setSelectedInventories={setSelectedInventories}
          />
        </Box>
      </Paper>
    </Box>
  );
}
