import { Box, Button, FormControl, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import Breadcrumbs from "components/common/Breadcrumbs";
import {
  FileUpload as FileUploadIcon,
  Download as DownloadIcon,
  PublishedWithChanges,
  ContentCopy as CopyIcon,
} from "@mui/icons-material";
import * as Yup from "yup";
import useDirectUpload from "hooks/useDirectUpload";
import { useCallback, useEffect, useState } from "react";
import usePartnerInventories from "hooks/usePartnerInventories";
import PartnerInventoryDataGrid from "./PartnerInventoryDataGrid";
import { useDropzone } from "react-dropzone";
import { readableDate } from "utils/readableDate";
import usePickup from "hooks/usePickup";
import { FRModal, IRModal } from "pages/PickupInventoriesPage";
import { debounce } from "lodash";
import { format, parseISO } from "date-fns";
import PickupStateChip from "components/PickupState";

export default function InventoryPage() {
  const { uploadPartnerInventory, count, partnerInventories } = usePartnerInventories();
  const { upload, blob } = useDirectUpload({ keyPrefix: `pickups/partner_inventories/`, service: "amazon_public" });
  const { irReady, frReady, forceFrReady, updatePickup, pickup } = usePickup();
  const [irOpen, setIrOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [frOpen, setFrOpen] = useState(false);
  const [ewasteValue, setEwasteValue] = useState(pickup.ewasteInLbs);
  const [ewasteValueMissingError, setEwasteValueMissingError] = useState(false);

  useEffect(() => {
    if (blob) {
      uploadPartnerInventory(blob?.signed_id).finally(() => {
        setLoading(false);
      });
    }
  }, [blob, uploadPartnerInventory]);

  const { open, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "file/csv": [".csv"],
    },
    onDrop: async (acceptedFiles: File[]) => {
      setLoading(true);
      upload(acceptedFiles[0]);
    },
  });

  const handleIrReady = () => {
    irReady(pickup.uuid).then(() => setIrOpen(false));
  };

  const handleFrReady = () => {
    frReady(pickup.uuid).then(() => setFrOpen(false));
  };

  const handleForceFrReady = () => {
    forceFrReady(pickup.uuid).then(() => setFrOpen(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceInput = useCallback(
    debounce((value) => {
      setEwasteValueMissingError(false);
      updatePickup({ ...pickup, ewasteInLbs: value });
    }, 1000),
    [pickup, updatePickup],
  );

  const copyAgentUUIDToClipboard = useCallback(() => {
    if (pickup.itad) navigator.clipboard.writeText(pickup.itad?.uuid);
  }, [pickup]);

  const handleEwasteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEwasteValue(Number(e.target.value));
    debounceInput(e.target.value);
  };

  useEffect(() => {
    setEwasteValue(pickup.ewasteInLbs);
  }, [pickup, setEwasteValue]);

  return (
    <Box>
      <Breadcrumbs
        data={[
          { label: "Logistics", to: "/logistics" },
          { label: "Pickups", to: "/logistics?logistics_status=past" },
          { label: `${pickup?.organization?.name} #${pickup?.orderNumber}` },
          { label: "Manage Inventory" },
        ]}
      ></Breadcrumbs>
      <Box display="flex" justifyContent="space-between" mt={4}>
        <Box>
          <Typography variant="h5">
            {pickup.organization.name} {pickup.scheduledAt ? readableDate(pickup.scheduledAt) : ""}
            <PickupStateChip pickupState={pickup.state} />
          </Typography>
          <Typography mt={0.5}>Add Partner Inventory and issue the IR and FR for the client.</Typography>
        </Box>
        {pickup.itad && (
          <Button startIcon={<CopyIcon />} onClick={copyAgentUUIDToClipboard}>
            Copy Partner uuid
          </Button>
        )}
      </Box>

      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} paddingTop={2}>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button variant="contained" startIcon={<FileUploadIcon />} onClick={open}>
                Upload Files
              </Button>
            </div>
            <Button
              variant="contained"
              color="inherit"
              href={process.env.PUBLIC_URL + "/partner_inventory_template.csv"}
              download
              style={{ textDecoration: "none", color: "#202020" }}
            >
              <DownloadIcon />
              Template
            </Button>
          </Stack>
        </Box>
        <Box>
          <Stack direction="row" justifyContent="space-between" alignItems="start" spacing={2}>
            <Button
              startIcon={<PublishedWithChanges />}
              onClick={() => {
                if (Yup.number().min(0).isValidSync(ewasteValue)) {
                  setIrOpen(true);
                } else {
                  setEwasteValueMissingError(true);
                }
              }}
              disabled={!!pickup.initialReportPublishedAt}
              variant="contained"
            >
              Publish IR{" "}
              {pickup.initialReportPublishedAt && format(parseISO(pickup.initialReportPublishedAt), "MM/dd/yy")}
            </Button>
            <IRModal open={irOpen} setOpen={setIrOpen} handleIrReady={handleIrReady} />

            <Button
              startIcon={<PublishedWithChanges />}
              onClick={() => {
                setFrOpen(true);
              }}
              disabled={!!pickup.finalReportPublishedAt}
              variant="contained"
            >
              Publish FR {pickup.finalReportPublishedAt && format(parseISO(pickup.finalReportPublishedAt), "MM/dd/yy")}
            </Button>
            <FRModal
              open={frOpen}
              setOpen={setFrOpen}
              handleFrReady={handleFrReady}
              handleForceFrReady={handleForceFrReady}
            />

            <FormControl>
              <TextField
                id="ewasteInLbs"
                name="ewasteInLbs"
                value={ewasteValue}
                size="small"
                label="E-waste"
                autoComplete="off"
                onChange={handleEwasteChange}
                error={ewasteValueMissingError}
                helperText={ewasteValueMissingError && "E-waste should be either 0 or a positive integer"}
                InputProps={{
                  endAdornment: <InputAdornment position="end">lbs</InputAdornment>,
                }}
                sx={{ width: "150px", pr: "20px" }}
              ></TextField>
            </FormControl>
          </Stack>
        </Box>
      </Stack>

      <Box>
        <PartnerInventoryDataGrid partnerInventories={partnerInventories} loading={loading} count={count} />
      </Box>
    </Box>
  );
}
