import { Alert, Box, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import CameraSetup from "components/Photobooth/CameraSetup";
import { steps, StepsKeys } from "components/Photobooth/photoBoothSteps";
import PhotoCapture from "components/Photobooth/PhotoCapture";
import PhotoReview from "components/Photobooth/PhotoReview";
import ScanInventory from "components/Photobooth/ScanInventory";
import { WebcamComponent } from "components/WebcamComponent";
import { audioSources } from "constants/audio_sources";
import { useAlert } from "hooks/useAlert";
import useInventory from "hooks/useInventory";
import useResalePhotos from "hooks/useInventoryImageResalePhotos";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import Webcam from "react-webcam";
import { base64ImageToFile } from "utils/blob";

enum CaptureAngle {
  Front,
  Left,
  Right,
  Top,
  Hero,
}

const captureOpenUpright = [CaptureAngle.Hero, CaptureAngle.Top];
const captureClosedUpright = [
  CaptureAngle.Front,
  CaptureAngle.Right,
  CaptureAngle.Left,
  CaptureAngle.Top,
  CaptureAngle.Hero,
];
const captureUpsideDown = [CaptureAngle.Top, CaptureAngle.Front];
const activeConfiguration: CaptureAngle[][] = [captureClosedUpright, captureOpenUpright, captureUpsideDown];
const captureSetImageFilename = [
  "front_edge",
  "right_edge",
  "left_edge",
  "top_face",
  "top_angle",
  "hero",
  "keyboard",
  "bottom_face",
  "back_edge",
];
const localStorageKey = "resale-photo-booth/device-map";
const onCaptureAudioElement = new Audio(audioSources.onCaptureReady);

export default function ResalePhotoBoothPage() {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const { inventory, setInventory, getInventoryById } = useInventory();
  const { uploadFiles, purgeAllResalePhotos } = useResalePhotos(inventory);
  const { alertError } = useAlert();
  const [deviceInfos, setDeviceInfos] = useState<Array<MediaDeviceInfo | null>>(Array(5).fill(null));
  const webcamRefs = useRef<Array<Webcam | null>>(Array(5).fill(null));
  const [currentSet, setCurrentSet] = useState(0);
  const [photoSets, setPhotoSets] = useState<string[][]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const canUseHotkey = useRef(true);
  const [showConfigurationPanel, setShowConfigurationPanel] = useState(false);
  const isInventoryLoaded = useMemo(() => !!inventory, [inventory]);
  const stepKeys: StepsKeys[] = ["stepOne", "stepTwo", "stepThree"];

  const handleCapture = () => {
    const set = activeConfiguration[currentSet];
    const photos = set.map((angle) => webcamRefs.current[angle]?.getScreenshot() ?? "");

    setPhotoSets((value) => [...value, photos]);
    setCurrentSet((value) => value + 1);
  };

  const activeStep = useMemo(() => {
    let step = 0;
    if (isInventoryLoaded) step++;
    step += photoSets.length;
    return step;
  }, [isInventoryLoaded, photoSets.length]);

  const handleAssignDeviceToAngle = (angleIndex: number, device: MediaDeviceInfo | null) => {
    setDeviceInfos((prev) => {
      const newDeviceInfos = [...prev];
      if (device) newDeviceInfos[angleIndex] = device;
      return newDeviceInfos;
    });
  };

  const handleReset = () => {
    setInventory(undefined);
    setPhotoSets([]);
    setCurrentSet(0);
  };

  const handleExistingResalePhotos = async () => {
    const existingPhotos = inventory && inventory.resalePhotos.length > 0;
    if (existingPhotos)
      purgeAllResalePhotos().then(async (sanitizedInventory) => {
        if (sanitizedInventory) setInventory(sanitizedInventory);
      });
  };

  const submitPhotos = async () => {
    setIsUploading(true);
    await handleExistingResalePhotos();
    const newPhotos = photoSets.flat(2);
    const files = newPhotos.flat().map((image, index) => base64ImageToFile(image, captureSetImageFilename[index]));
    uploadFiles(files)
      .then((result) => {
        if (!result.includes(false)) handleReset();
      })
      .catch((err) => {
        alertError("Failed to upload photos. Please try again.");
        console.error("Failed to upload photos:", err);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const handleHotkeyCooldown = () => {
    canUseHotkey.current = false;
    setTimeout(() => (canUseHotkey.current = true), 200);
  };

  useHotkeys(
    "mod+g",
    () => {
      if (!!inventory && canUseHotkey) {
        if (currentSet < activeConfiguration.length) handleCapture();
        else if (!isUploading) submitPhotos();
        onCaptureAudioElement.play();
        handleHotkeyCooldown();
      }
    },
    { description: "Capture Photo / Confirm", enableOnFormTags: ["INPUT"], preventDefault: true },
  );

  const saveCameraMapping = () => {
    localStorage.setItem(localStorageKey, JSON.stringify(deviceInfos));
  };

  const loadCameraMapping = () => {
    const mapping = localStorage.getItem(localStorageKey);
    setDeviceInfos(mapping ? JSON.parse(mapping) : [null, null, null, null, null]);

    if (mapping) {
      const data = JSON.parse(mapping) as (MediaDeviceInfo | null)[];
      if (data.some((device) => device === null)) {
        setShowConfigurationPanel(true);
      }
    } else {
      setShowConfigurationPanel(true);
    }
  };

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) =>
      setDevices(mediaDevices.filter(({ kind }: { kind: string }) => kind === "videoinput")),
    [setDevices],
  );

  const handleEnumerateDevices = useCallback(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    handleEnumerateDevices();
    navigator.mediaDevices.ondevicechange = handleEnumerateDevices;
  }, [handleEnumerateDevices]);
  useEffect(loadCameraMapping, []);
  useEffect(saveCameraMapping, [deviceInfos]);

  return (
    <Box>
      {showConfigurationPanel ? (
        <CameraSetup
          devices={devices}
          deviceInfos={deviceInfos}
          setDeviceCallback={handleAssignDeviceToAngle}
          setShowConfigurationPanel={setShowConfigurationPanel}
        />
      ) : (
        <>
          <Box>
            <Stepper alternativeLabel activeStep={activeStep} sx={{ my: 4 }}>
              <Step completed={isInventoryLoaded}>
                <StepLabel>Scan Label</StepLabel>
              </Step>

              {activeConfiguration.map((_value, index) => (
                <Step key={index} completed={photoSets.length > index}>
                  <StepLabel>Set {index + 1}</StepLabel>
                </Step>
              ))}

              <Step last completed={isUploading}>
                <StepLabel>Review</StepLabel>
              </Step>
            </Stepper>
            {inventory && inventory.resalePhotos.length > 0 && (
              <Alert severity="info" sx={{ marginY: 1 }}>
                {inventory.label} already has resale photos. Uploading a new set of photos will delete the existing set.
              </Alert>
            )}
          </Box>
          {photoSets.length >= activeConfiguration.length ? (
            <PhotoReview
              photoSets={photoSets}
              isUploading={isUploading}
              handleReset={handleReset}
              submitPhotos={submitPhotos}
              filenames={captureSetImageFilename}
            />
          ) : (
            <>
              {isInventoryLoaded ? (
                <PhotoCapture
                  handleCapture={handleCapture}
                  handleReset={handleReset}
                  photoCaptureCheck={steps[stepKeys[activeStep - 1]]?.photoCaptureCheck || []}
                  gifSource={steps[stepKeys[activeStep - 1]]?.gifSource || ""}
                  color={steps[stepKeys[activeStep - 1]]?.color || ""}
                  batch={steps[stepKeys[activeStep - 1]]?.batch || "0"}
                  displayDevice={deviceInfos[CaptureAngle.Top]}
                />
              ) : (
                <ScanInventory
                  getInventoryById={getInventoryById}
                  handleOpenCameraSettings={() => setShowConfigurationPanel(true)}
                />
              )}
            </>
          )}
        </>
      )}
      <Grid
        container
        marginY={2}
        spacing={2}
        sx={showConfigurationPanel ? {} : { position: "absolute", top: 0, left: 0, width: 0, height: 0 }}
      >
        <Grid item>
          <Typography variant="h6">Front</Typography>
          <WebcamComponent
            device={deviceInfos[CaptureAngle.Front]}
            ref={(el) => (webcamRefs.current[CaptureAngle.Front] = el)}
          />
        </Grid>

        <Grid item>
          <Typography variant="h6">Left</Typography>
          <WebcamComponent
            device={deviceInfos[CaptureAngle.Left]}
            ref={(el) => (webcamRefs.current[CaptureAngle.Left] = el)}
          />
        </Grid>

        <Grid item>
          <Typography variant="h6">Right</Typography>
          <WebcamComponent
            device={deviceInfos[CaptureAngle.Right]}
            ref={(el) => (webcamRefs.current[CaptureAngle.Right] = el)}
          />
        </Grid>

        <Grid item>
          <Typography variant="h6">Top</Typography>
          <WebcamComponent
            device={deviceInfos[CaptureAngle.Top]}
            ref={(el) => (webcamRefs.current[CaptureAngle.Top] = el)}
          />
        </Grid>

        <Grid item>
          <Typography variant="h6">Hero</Typography>
          <WebcamComponent
            device={deviceInfos[CaptureAngle.Hero]}
            ref={(el) => (webcamRefs.current[CaptureAngle.Hero] = el)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
