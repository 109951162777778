import { useAlert } from "hooks/useAlert";
import { Form, Formik, FormikConfig, FormikHelpers, FormikValues } from "formik";
import { api } from "services/api.service";
import { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { FormHTMLAttributes, ReactNode } from "react";

function getNamespacedKey(namespace: string | undefined, key: string) {
  if (namespace) {
    return `${namespace}[${key}]`;
  } else {
    return key;
  }
}

function convertObjectToFormData(obj: FormikValues, formData: FormData, namespace?: string) {
  for (const [key, val] of Object.entries(obj)) {
    if (val instanceof File) {
      formData.append(getNamespacedKey(namespace, key), val);
    } else if (val instanceof Array) {
      val.forEach((item, i) => {
        convertObjectToFormData(item, formData, `${getNamespacedKey(namespace, key)}[${i}]`);
      });
    } else if (val instanceof Object) {
      convertObjectToFormData(val, formData, namespace ? `${namespace}[${key}]` : key);
    } else {
      formData.append(getNamespacedKey(namespace, key), val);
    }
  }
}

function convertToFormData(data: FormikValues) {
  const formData = new FormData();
  convertObjectToFormData(data, formData);
  return formData;
}

interface RevivnApiFormProps<Values> {
  initialValues: Values;
  action: string;
  method: Method;
  children: ReactNode;
  enctype?: string;
  onSubmit?: (data: Values, formikBag: FormikHelpers<Values>) => void;
  onSettled?: (formikBag: FormikHelpers<Values>) => void;
  onError?: (error: AxiosError) => void;
  onSuccess?: (response: AxiosResponse) => void;
  FormProps?: FormHTMLAttributes<HTMLFormElement>;
  FormikProps?: Partial<FormikConfig<Values>>;
}

export function RevivnApiForm<Values>(props: RevivnApiFormProps<Values>) {
  const { alertError } = useAlert();

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={async (data, formikBag) => {
        props.onSubmit?.(data, formikBag);
        const additionalConfig: AxiosRequestConfig = {};
        if (props.enctype === "multipart/form-data") {
          additionalConfig.headers = { "Content-Type": "multipart/form-data" };
          additionalConfig.data = convertToFormData(data);
        }
        try {
          const result = await api.request({
            url: props.action,
            method: props.method,
            data: data,
            ...additionalConfig,
          });
          props.onSuccess?.(result);
        } catch (err) {
          props.onError?.(err as AxiosError);
          const response = (err as AxiosError)?.response;
          const errors = response?.data?.errors;
          if (errors && response?.status === 422 && errors instanceof Object) {
            for (const [key, val] of Object.entries(errors as Record<string, string>)) {
              formikBag.setFieldError(key, val);
              formikBag.setFieldTouched(key, true, false);
            }
          } else if (!props.onError) {
            alertError((err as AxiosError)?.message);
          }
        }
        props.onSettled?.(formikBag);
      }}
      {...props.FormikProps}
    >
      <Form action={props.action} method={props.method} {...props.FormProps}>
        {props.children}
      </Form>
    </Formik>
  );
}
