import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { InventoryUpload } from "types/inventoryUploads";

export default function useInventoryUploads() {
  const [inventoryUploads, setInventoryUploads] = useState<InventoryUpload[]>([]);
  const [downloadLink, setDownloadLink] = useState<string>(" ");

  const [totalPages, setTotalPages] = useState<number>(0);
  const { search } = useLocation();

  const getInventoryUploads = useCallback(() => {
    api.get(`/inventory_uploads${search}`).then(
      ({
        data: {
          inventoryUploads: fetchedInventoryUploads,
          meta: { totalPages: fetchedTotalPages },
        },
      }) => {
        setInventoryUploads(fetchedInventoryUploads);
        setTotalPages(fetchedTotalPages);
      },
    );
  }, [search]);

  const createInventoryUpload = (url: string) => {
    return api.post(`/inventory_uploads`, { inventory_upload: { file_url: url } }).then(() => {
      getInventoryUploads();
    });
  };

  const createInventoryPricerUpload = (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    api
      .post(`/inventory_pricer_uploads`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data: { url } }) => {
        setDownloadLink(url);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    return;
  };

  useEffect(() => {
    getInventoryUploads();
  }, [getInventoryUploads, search]);

  return {
    createInventoryUpload,
    createInventoryPricerUpload,
    inventoryUploads,
    getInventoryUploads,
    totalPages,
    downloadLink,
  };
}
