import { GridColTypeDef } from "@mui/x-data-grid-pro";
import { getCurrentEasternTimezoneName } from "utils/helpers";
import { Tooltip } from "@mui/material";

export const dateColDef: GridColTypeDef = {
  type: "date",
  renderCell: (params) => {
    if (!params.value) return "";
    // show the full timestamp in a tooltip
    const date = Date.parse(params.value);
    const dateFormatted = Intl.DateTimeFormat("en-US", { timeZone: "America/New_York" }).format(date);
    const dateWithTime = Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "America/New_York",
    }).format(date);
    return (
      <Tooltip title={`${dateWithTime} ${getCurrentEasternTimezoneName()}`}>
        <span>{dateFormatted}</span>
      </Tooltip>
    );
  },
};
