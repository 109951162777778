import {
  useCallback,
  useEffect,
  useContext,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  useMemo,
} from "react";
import { Params, useNavigate, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { Organization } from "types/organization";
import { defaultPickup, NewPickup } from "types/pickup";
import { useAlert } from "./useAlert";
import useOrganizations from "./useOrganizations";
import { Location } from "types/location";
import { PickupContact } from "types/pickupContact";
import { PickupEstimatedItem } from "types/pickupEstimatedItem";

interface ContextValue {
  pickup: NewPickup;
  organization?: Organization;
  selectedLocation?: Location;
  mainContact?: string;
  setPickup: Dispatch<SetStateAction<NewPickup>>;
  setMainContact: Dispatch<SetStateAction<string | undefined>>;
  setSelectedLocation: Dispatch<SetStateAction<Location | undefined>>;
  setOrganization: Dispatch<SetStateAction<Organization | undefined>>;
  params: Readonly<Params<string>>;
  updatePickup: (pickup: NewPickup) => Promise<void>;
  fetchPickup: () => void;
}

export const LogisticsPickupContext = createContext({} as ContextValue);

export const LogisticsPickupProvider = ({ children }: { children: React.ReactNode }) => {
  const params = useParams();
  const [pickup, setPickup] = useState<NewPickup>(defaultPickup);
  const [organization, setOrganization] = useState<Organization>();
  const [selectedLocation, setSelectedLocation] = useState<Location>();
  const [mainContact, setMainContact] = useState<string>();
  const { alertError } = useAlert();

  const fetchPickup = useCallback(() => {
    if (params.pickupUuid !== undefined && params.pickupUuid) {
      api.get(`/logistics_pickups/${params.pickupUuid}`).then(({ data }) => {
        setPickup(data);
        setOrganization(data.organization);
      });
    }
  }, [params.pickupUuid]);

  useEffect(() => {
    fetchPickup();
  }, [fetchPickup, params.pickupUuid]);

  useEffect(() => {
    if (organization?.id !== pickup.organization?.id) setOrganization(pickup.organization);
  }, [organization?.id, pickup.organization]);

  const updatePickup = useCallback(
    (updatedPickup: NewPickup) => {
      const {
        locationId,
        organizationId,
        reason,
        scheduledAt,
        internalNotes,
        confirmedTime,
        pickupEstimatedItems,
        pickupContacts,
        contacts,
      } = updatedPickup;
      return api
        .put(`/logistics_pickups/${params.pickupUuid}`, {
          pickup: {
            locationId,
            organizationId,
            reason,
            scheduledAt,
            internalNotes,
            confirmedTime,
            pickupEstimatedItems,
            pickupContacts,
            contacts,
          },
        })
        .then(({ data }) => {
          setPickup(data);
        })
        .catch(() => {
          alertError("There was an error, please try again");
        });
    },
    [alertError, params.pickupUuid],
  );

  return (
    <LogisticsPickupContext.Provider
      value={{
        pickup,
        organization,
        selectedLocation,
        mainContact,
        setMainContact,
        setSelectedLocation,
        setOrganization,
        params,
        setPickup,
        updatePickup,
        fetchPickup,
      }}
    >
      {children}
    </LogisticsPickupContext.Provider>
  );
};

export function usePickupEstimatedItems() {
  const { pickup, updatePickup } = useContext(LogisticsPickupContext);
  const [isSaved, setIsSaved] = useState(false);
  const [items, setItems] = useState<PickupEstimatedItem[]>(
    pickup.pickupEstimatedItems.map((item) => {
      return { name: item.name, quantity: item.quantity };
    }),
  );
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      updatePickup({ ...pickup, pickupEstimatedItems: items }).then(() => {
        setIsSaved(true);
      });
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, updatePickup]);

  return { items, setItems, pickup, updatePickup, isSaved };
}

export function usePickupContact() {
  const { setMainContact, pickup, mainContact, organization, updatePickup } = useContext(LogisticsPickupContext);

  const updatePickupContact = (contact: PickupContact) => {
    const { id, email, firstName, lastName, phoneNumber } = contact;

    return updatePickup({
      ...pickup,
      pickupContacts: [
        ...pickup.pickupContacts.filter(({ pointOfContactType }) => pointOfContactType !== "main"),
        { id, pointOfContactType: "main", email, firstName, lastName, phoneNumber },
      ],
    });
  };

  const currentMainContact = useMemo(() => {
    return pickup?.pickupContacts.find((contact) => contact.pointOfContactType === "main");
  }, [pickup]);

  useEffect(() => {
    if (!currentMainContact) return;
    setMainContact(currentMainContact.contactId);
  }, [currentMainContact, setMainContact]);

  return { updatePickupContact, mainContact, setMainContact, organization, pickup };
}

export function usePickupOtherContacts() {
  const { pickup, updatePickup, organization } = useContext(LogisticsPickupContext);

  const updatePickupOtherContact = (emails: string[]) => {
    return updatePickup({
      ...pickup,
      pickupContacts: [
        ...emails.map((email: string) => {
          return { pointOfContactType: "notifications", email: email };
        }),
        ...pickup.pickupContacts.filter(({ pointOfContactType }) => pointOfContactType === "main"),
      ],
    });
  };

  return {
    updatePickupOtherContact,
    emails: pickup.pickupContacts
      .filter(({ pointOfContactType }) => pointOfContactType !== "main")
      .map(({ email }) => email)
      .filter((email) => email),
    organizationUserEmails:
      (organization?.contacts
        ?.filter(({ pointOfContactType }) => pointOfContactType !== "main")
        .map(({ email }) => email)
        ?.filter((email) => email) as string[]) || [],
  };
}

export function usePickupImages() {
  const { params, fetchPickup, pickup } = useContext(LogisticsPickupContext);

  const createImage = useCallback(
    (imageKey: string) => {
      return api
        .post<NewPickup>(`/logistics_pickups/${params.pickupId || params.pickupUuid}/pickup_images`, { imageKey })
        .then(() => {
          fetchPickup();
        });
    },
    [fetchPickup, params.pickupId, params.pickupUuid],
  );

  const deleteImage = useCallback(
    (attachmentId: number) => {
      return api
        .delete<NewPickup>(
          `/logistics_pickups/${params.pickupId || params.pickupUuid}/pickup_images?attachment_id=${attachmentId}`,
        )
        .then(() => {
          fetchPickup();
        });
    },
    [fetchPickup, params.pickupId, params.pickupUuid],
  );
  return { deleteImage, createImage, pickup };
}

export function useCreatePickup() {
  const { organizations, searchOrganizations } = useOrganizations();
  const { setOrganization, setSelectedLocation, organization, selectedLocation } = useContext(LogisticsPickupContext);
  const { alertError } = useAlert();
  const navigate = useNavigate();

  const getOrganization = (pickup: NewPickup) => {
    api.get(`/organizations/${pickup?.organization?.uuid}`).then(({ data }) => {
      setOrganization(data);
    });
  };

  const getLocation = (pickup: NewPickup) => {
    if (pickup?.location?.uuid) {
      return api.get(`/clients/${pickup?.organization?.uuid}/locations/${pickup.location.uuid}`).then(({ data }) => {
        setSelectedLocation(data);
      });
    }
  };

  const createPickup = (pickup: { organizationId: string }) => {
    return api
      .post("/logistics_pickups", { pickup })
      .then(({ data }) => {
        navigate(`/logistics/pickups/${data.uuid}/edit`);
        getOrganization(data);
      })
      .catch(() => {
        alertError("There was an error, please try again");
      });
  };

  return {
    getOrganization,
    getLocation,
    organization,
    selectedLocation,
    organizations,
    searchOrganizations,
    createPickup,
  };
}

export function useSchedulePickup() {
  const { alertSuccess, alertError } = useAlert();
  const { params } = useContext(LogisticsPickupContext);

  const schedulePickup = () => {
    return api
      .post(`/logistics_pickups/${params.pickupUuid}/schedule`)
      .then(() => {
        alertSuccess(`Successfully scheduled the pickup`);
      })
      .catch(() => {
        alertError("There was an error, please try again");
      });
  };
  return schedulePickup;
}
