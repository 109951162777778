import { useCallback, useEffect, useState } from "react";

export type ReadCompleted = (inventoryId?: string | null, warehouseLocation?: string | null) => void;

export type UserScannerProps = {
  readCompleted: ReadCompleted;
};

const getCameraDefaultFromLocalStorage = () => {
  const storageValue = localStorage.getItem("cameraDefault");
  if (!storageValue) return null;
  return JSON.parse(storageValue);
};

export default function useQrScanner({ readCompleted }: { readCompleted: ReadCompleted }) {
  const [processing, setProcessing] = useState(false);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [cameraFacing, setCameraFacing] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo>();

  const onScan = useCallback(
    (data: { text: string } | null) => {
      if (data && !processing) {
        try {
          readCompleted(data.text);
        } catch {
          readCompleted(null, data.text);
        } finally {
          setProcessing(true);
        }
      }
    },
    [readCompleted, processing, setProcessing],
  );

  const onError = useCallback(() => {}, []);

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput"));
    });
  }, []);

  useEffect(() => {
    if (devices[0]) {
      setSelectedDevice(getCameraDefaultFromLocalStorage() || devices[0]);
    }
  }, [devices]);

  useEffect(() => {
    const localStorageValue = getCameraDefaultFromLocalStorage();
    if (selectedDevice && (localStorageValue === null || localStorageValue.deviceId !== selectedDevice?.deviceId)) {
      localStorage.setItem("cameraDefault", JSON.stringify(selectedDevice));
    }
  }, [selectedDevice]);

  return {
    processing,
    devices,
    selectedDevice,
    setCameraFacing,
    cameraFacing,
    setSelectedDevice,
    readerProps: {
      delay: 300,
      style: { width: "100%" },
      constraints: {
        video: {
          deviceId: {
            exact: selectedDevice?.deviceId,
          },
          facingMode: cameraFacing ? "user" : "environment",
        },
      },
      onScan,
      onError,
    },
  };
}
