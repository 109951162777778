import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";

type Props = {
  amountInCents: number;
  airbaseId?: string;
  waived: boolean;
  description?: string;
  internalNotes?: string;
  publishedAt?: string;
  pickupId?: number | null;
};

export default function useEarnings() {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [earnings, setEarnings] = useState<number | null>(null);
  const [transactions, setTransactions] = useState<Props[] | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  const getEarnings = useCallback(() => {
    setLoading(true);
    api.get(`/organizations/${params.uuid}/earnings${search}`).then(
      ({
        data: {
          earningsBalance,
          earningsTransactions,
          meta: { totalPages: totalMetaPages, count: metaCount },
        },
      }) => {
        setEarnings(earningsBalance);
        setTransactions(earningsTransactions);
        setTotalPages(totalMetaPages);
        setCount(metaCount);
      },
    );
  }, [params.uuid, search]);

  const createEarningsTransaction = (earningsTransaction: Props) => {
    earningsTransaction.amountInCents = Math.ceil(earningsTransaction.amountInCents * 100);
    api.post(`/organizations/${params.uuid}/earnings`, {
      earningsTransaction,
    });
  };

  useEffect(() => {
    getEarnings();
  }, [getEarnings, search]);

  return {
    earnings,
    createEarningsTransaction,
    transactions,
    totalPages,
    count,
    isLoading,
  };
}
