import { Box, Button, CircularProgress, Divider, Grid, Grow, Paper, Stack, Typography } from "@mui/material";
import ImageOverlay from "components/ImageOverlay";

interface PhotoReviewProps {
  photoSets: string[][];
  filenames: string[];
  isUploading: boolean;
  handleReset: () => void;
  submitPhotos: () => void;
}

export default function PhotoReview({
  photoSets,
  isUploading,
  handleReset,
  submitPhotos,
  filenames,
}: PhotoReviewProps) {
  return (
    <Box component={Paper} variant={"outlined"} padding={4}>
      <Stack direction="column" spacing={4}>
        <Stack direction="column">
          <Typography fontSize="24px" fontWeight={600}>
            Review Photos
          </Typography>
          <Typography fontSize="14px" fontWeight={400}>
            Review all photos carefully, ensuring they are high quality, the laptop is clearly visible in all photos,
            photos are not blurry, and there are no blemishes or marks.
          </Typography>
        </Stack>
        <Grid container spacing={1}>
          {photoSets.flat().flatMap((photoSet, index) => (
            <Grid item key={index}>
              <Grow in timeout={{ enter: index * 200 }}>
                <Stack component={Paper} maxWidth={256} padding={1} spacing={1}>
                  <Box
                    width="100%"
                    height="100%"
                    position="relative"
                    sx={{
                      aspectRatio: "16/9",
                      "& .showOnHover": { opacity: 0, transition: "0.2s opacity" },
                      "&:hover .showOnHover": { opacity: 1 },
                    }}
                  >
                    <img src={photoSet} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                    <ImageOverlay image={photoSet} showExpandButton />
                  </Box>
                  <Typography>{filenames[index]}</Typography>
                </Stack>
              </Grow>
            </Grid>
          ))}
        </Grid>
        <Divider sx={{ my: 4 }} />

        <Stack direction="row" spacing={4} justifyContent="end">
          <Button onClick={handleReset} color="error" variant="text" disabled={isUploading}>
            Restart
          </Button>
          <Button
            onClick={submitPhotos}
            color="primary"
            variant="contained"
            endIcon={isUploading && <CircularProgress size={20} />}
            disabled={isUploading}
          >
            Submit Photos
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
