import { AddCircleOutline, Delete } from "@mui/icons-material";
import {
  Box,
  Typography,
  Link,
  MenuItem,
  FormControl,
  IconButton,
  InputLabel,
  Alert,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import { theme } from "index";
import { AppSelect, InputBase } from "components/common/Input";
import { usePickupEstimatedItems } from "hooks/useLogisticsPickup";
import { ChangeEvent } from "react";

export default function PickupEstimatedItems() {
  const { items, setItems, isSaved } = usePickupEstimatedItems();

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    let newItems = items;

    newItems = items.map((obj) => {
      if (obj === items[index]) {
        return { name: items[index].name, quantity: parseInt(event.target.value) };
      }
      return obj;
    });

    setItems(newItems);
  };

  const handleNameChange = (event: SelectChangeEvent<string>, index: number) => {
    let newItems = items;

    newItems = items.map((obj) => {
      if (obj === items[index]) {
        return { name: event.target.value, quantity: items[index].quantity };
      }
      return obj;
    });

    setItems(newItems);
  };

  const handleDelete = (index: number) => {
    let newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  return (
    <Box>
      <Grid container justifyContent="space-between" direction="row" alignItems="center" sx={{ p: "10px", pr: "10px" }}>
        <Typography variant="h6" fontSize="18px" fontWeight="bold">
          3. What’s in their pickup?
        </Typography>
        {isSaved ? <Alert>Saved</Alert> : null}
      </Grid>

      <Typography mt={0.5} variant="body2">
        Let’s breakdown what’s in their pickup. If you’re not exactly sure, take your best guess or click on ‘not sure’
        for additional estimates.
      </Typography>

      <Box sx={{ mt: 3 }}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 40px",
            gap: "12px",
            [theme.breakpoints.down("sm")]: { display: "none" },
          }}
        >
          <Typography fontWeight={600}>What are we picking up?</Typography>
          <Typography fontWeight={600}>Quantity of devices</Typography>
        </Box>

        {items.map((item, i) => {
          return (
            <Box
              key={i}
              sx={{
                mt: "12px",
                display: "grid",
                gridTemplateColumns: "1fr 40px",
                gap: "12px",
                [theme.breakpoints.down("sm")]: { mt: "24px" },
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr",
                  gap: "12px",
                  [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr" },
                }}
              >
                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel shrink htmlFor="picking-ups" sx={{ [theme.breakpoints.up("sm")]: { display: "none" } }}>
                    What are we picking up?
                  </InputLabel>
                  <AppSelect
                    id="picking-ups"
                    onChange={(e) => {
                      handleNameChange(e, i);
                    }}
                    value={item.name}
                  >
                    <MenuItem value={"Apple Laptops"}>Apple Laptops</MenuItem>
                    <MenuItem value={"PC Laptops"}>PC Laptops</MenuItem>
                    <MenuItem value={"Chromebooks"}>Chromebooks</MenuItem>
                    <MenuItem value={"Laptops"}>Laptops Generic</MenuItem>
                    <MenuItem value={"Desktops"}>Desktops</MenuItem>
                    <MenuItem value={"Monitors"}>Monitors</MenuItem>
                    <MenuItem value={"Tablets"}>Tablets</MenuItem>
                    <MenuItem value={"Televisions"}>Televisions</MenuItem>
                    <MenuItem value={"Servers"}>Servers</MenuItem>
                    <MenuItem value={"Printers"}>Printers</MenuItem>
                    <MenuItem value={"Small Box"}>Small Box</MenuItem>
                    <MenuItem value={"Large Box"}>Large Box</MenuItem>
                    <MenuItem value={"Commercial Bin"}>Commercial Bin</MenuItem>
                    <MenuItem value={"Office Phones"}>Office Phones</MenuItem>
                  </AppSelect>
                </FormControl>

                <FormControl variant="standard" sx={{ width: "100%" }}>
                  <InputLabel
                    shrink
                    htmlFor="pickups-quantity"
                    sx={{ [theme.breakpoints.up("sm")]: { display: "none" } }}
                  >
                    Quantity of devices
                  </InputLabel>
                  <InputBase
                    id="pickups-quantity"
                    type="number"
                    sx={[{ ".MuiOutlinedInput-root": { height: "38px !important" } }]}
                    value={item.quantity}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleQuantityChange(e, i);
                    }}
                  />
                </FormControl>
              </Box>

              <IconButton
                onClick={(e) => {
                  handleDelete(i);
                }}
              >
                <Delete />
              </IconButton>
            </Box>
          );
        })}
      </Box>

      <Link
        component="button"
        onClick={() => {
          setItems([...items, { name: "Apple Laptops", quantity: 100 }]);
        }}
        sx={{
          mt: 1,
          display: "inline-flex",
          alignItems: "center",
          gap: 1,
          fontWeight: 600,
          textDecoration: "none",
          fontSize: "16px",
          fontFamily: "Catamaran",
          ":hover": {
            textDecoration: "underline",
          },
          [theme.breakpoints.down("sm")]: { mt: 2 },
        }}
      >
        <AddCircleOutline sx={{ width: "20px", height: "20px" }} />
        Add more items to pickup
      </Link>
    </Box>
  );
}
