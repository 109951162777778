import * as Yup from "yup";

const Apple = "apple";
const exceptions = ["iser"];

export const inventoryEditValidation = Yup.object().shape({
  serialNumber: Yup.string()
    .nullable()
    .when("manufacturer", {
      is: (manufacturer: string) => manufacturer?.toLowerCase()?.includes(Apple),
      then: Yup.string()
        .nullable()
        .test("valid-apple-serial", "cannot include 'O' (ohhh)", (serialNumber) => {
          if (serialNumber === undefined || serialNumber === null) return true;
          return !serialNumber?.toLowerCase()?.includes("o");
        })
        .test("valid-apple-serial", "cannot include 'I' (eye)", (serialNumber) => {
          if (
            serialNumber === undefined ||
            serialNumber === null ||
            exceptions.find((a) => a.toLowerCase() === serialNumber?.toLowerCase())
          )
            return true;
          return !serialNumber?.toLowerCase()?.includes("i");
        }),
    }),
});

const WAREHOUSE_LOCATION_PREFIXES = [
  "AREA",
  "CLNG",
  "CLNQ",
  "DONA",
  "ECOM",
  "ENRL",
  "EWST",
  "INDS",
  "MBLE",
  "MISS",
  "MRCH",
  "PHTQ",
  "PLLT",
  "PNDR",
  "QUAL",
  "RCVG",
  "RECO",
  "RECQ",
  "RESL",
  "RPHT",
  "RPHQ",
  "RTNQ",
  "RTNT",
  "SCND",
  "SORT",
  "SRCQ",
  "STAG",
  "TECH",
  "TECQ",
  "TSFR",
  "WHOL",
  "ZONE",
];

export const warehouseLocationValidation = Yup.object().shape({
  location: Yup.string()
    .required("Location is required")
    .test("Check location prefix", (value) => {
      if (!value) return false;
      return WAREHOUSE_LOCATION_PREFIXES.includes(value.slice(0, 4));
    }),
});
