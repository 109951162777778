import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";

export default function useEarnings() {
  const params = useParams();
  const [isLoading, setLoading] = useState(false);
  const [earnings, setEarnings] = useState<number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [transactionGroups, setTransactionGroups] = useState<any>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  const getEarnings = useCallback(() => {
    setLoading(true);
    api.get(`/organizations/${params.uuid}/transaction_groups${search}`).then(
      ({
        data: {
          earningsBalance,
          transactionGroups: fetchedTransactionGroups,
          meta: { totalPages: fetchedTotalPages, count: fetchedCount },
        },
      }) => {
        setEarnings(earningsBalance);
        setTransactionGroups(fetchedTransactionGroups);
        setTotalPages(fetchedTotalPages);
        setCount(fetchedCount);
      },
    );
  }, [params.uuid, search]);

  useEffect(() => {
    getEarnings();
  }, [getEarnings, search]);

  return {
    earnings,
    transactionGroups,
    totalPages,
    count,
    isLoading,
  };
}
