import { GridFeatureMode, GridFilterModel, GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { GridFilterItem, GridLinkOperator } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toSnake, toCamel } from "ts-case-convert";
import { useQuery } from "./useQuery";

interface PageState {
  page: number;
  pageSize: number | undefined;
  sortModel: GridSortModel;
  loading: boolean;
  filterModel: GridFilterModel;
}

const defaultPageSize = 25;

export default function useServerSideDataGridParams(dependency: any) {
  const query = useQuery();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string | undefined>();

  const initialPageNumber = query.get("page[number]");
  const initialPageSize = query.get("page[size]");
  const initialSortModel = query.get("sort");
  const initialFilterModel = query.getAll("filter[]");
  const initialFilterLinkModel = query.get("filter_link_operator");

  const parseUrlSort = (sort: string) => {
    return sort.split(",").map((column) => {
      let direction: GridSortDirection = "asc";
      if (column[0] === "-") {
        direction = "desc";
        column = column.substring(1);
      }
      return {
        field: column,
        sort: direction,
      };
    });
  };

  const parseUrlFilter = (
    filterQuery: string[],
    initialFilterLinkModel: string | null,
  ): { items: GridFilterItem[]; linkOperator?: GridLinkOperator } => {
    const filterItems: GridFilterItem[] = [];
    filterQuery.forEach((filter) => {
      const filterArray = filter.split(",");
      const values = filterArray.slice(2);
      filterItems.push({
        columnField: toCamel(filterArray[0]),
        operatorValue: toCamel(filterArray[1]),
        value: values,
      });
    });
    if (initialFilterLinkModel == "And" || initialFilterLinkModel == "Or") {
      return { items: filterItems, linkOperator: GridLinkOperator[initialFilterLinkModel] };
    }
    return { items: filterItems };
  };

  const [pageState, setPageState] = useState<PageState>({
    page: initialPageNumber ? parseInt(initialPageNumber) : 0,
    pageSize: initialPageSize ? parseInt(initialPageSize) : defaultPageSize,
    sortModel: initialSortModel ? parseUrlSort(initialSortModel) : [],
    filterModel: parseUrlFilter(initialFilterModel, initialFilterLinkModel),
    loading: false,
  });

  const changePage = (page: PageState) => {
    setPageState({ ...page, loading: true });

    query.forEach((_, k) => {
      if (["page[number]", "page[size]", "sort", "filter[][key]", "filter[][value]", "filter[][operator]"].includes(k))
        query.delete(k);
    });
    if (!isNaN(page.page)) {
      query.append("page[number]", String(page.page + 1));
    }
    if (page.pageSize) {
      query.append("page[size]", String(page.pageSize));
    }
    if (page.sortModel.length > 0) {
      const sortQuery = page.sortModel
        .map((sort) => {
          return `${sort.sort === "asc" ? "" : "-"}${toSnake(sort.field)}`;
        })
        .join(",");
      query.append(`sort`, sortQuery);
    }

    query.delete("filter[]");
    query.delete("filter_link_operator");
    if (page.filterModel?.items.length > 0) {
      page.filterModel.items.forEach((item) => {
        if (item.value !== undefined) {
          const column = toSnake(item.columnField);
          const value = item.value;
          const operator = item.operatorValue ? toSnake(item.operatorValue) : "";
          const filter_query = `${column},${operator},${value}`;
          query.append("filter[]", filter_query);
        }
      });
      if (page.filterModel.linkOperator)
        query.append("filter_link_operator", page.filterModel.linkOperator.toUpperCase());
    }
    navigate(`?${query.toString()}`);
  };

  useEffect(() => {
    setPageState((prevPageState) => {
      return { ...prevPageState, loading: false };
    });
  }, [dependency]);

  useEffect(() => {
    if (query.get("q")) query.delete("q");

    if (searchQuery && searchQuery.length > 0) query.append("q", searchQuery);

    if (searchQuery != undefined) navigate(`?${query.toString()}`);
  }, [searchQuery]);

  const mode: GridFeatureMode = "server";
  const enablePagination: true = true;

  return {
    pagination: enablePagination,
    ...pageState,
    paginationMode: mode,
    onPageChange: (page: PageState["page"]) => changePage({ ...pageState, page: page }),
    onPageSizeChange: (pageSize: PageState["pageSize"]) => changePage({ ...pageState, pageSize: pageSize }),
    sortingMode: mode,
    onSortModelChange: (sortModel: PageState["sortModel"]) =>
      changePage({ ...pageState, sortModel: sortModel, page: 0 }),
    setSearchQuery,
    onFilterModelChange: (filterModel: PageState["filterModel"]) =>
      changePage({ ...pageState, filterModel: filterModel }),
    parseUrlFilter,
  };
}
