import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import InfoIcon from "@mui/icons-material/Info";
import InputIcon from "@mui/icons-material/Input";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MemoryIcon from "@mui/icons-material/Memory";
import MonitorIcon from "@mui/icons-material/Monitor";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Portal,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Tags from "components/Inventories/Tags";
import DeviceWipeStatus from "components/TechnicianInventory/DeviceWipeStatus";
import FunctionalitySwitches from "components/TechnicianInventory/FunctionalitySwitches";
import InventoryAttributes from "components/TechnicianInventory/InventoryAttributes";
import InventoryChangeLocationButton from "components/TechnicianInventory/InventoryChangeLocationButton";
import InventoryEventHistoryButton from "components/TechnicianInventory/InventoryEventHistoryButton";
import InventoryPartsBulkInbound from "components/TechnicianInventory/InventoryPartsBulkInbound";
import InventoryPartsPanel from "components/TechnicianInventory/InventoryPartsPanel";
import InventoryPrintLabelButton from "components/TechnicianInventory/InventoryPrintLabelButton";
import TechForm from "components/TechnicianInventory/TechForm";
import TechnicianInventoryImages from "components/TechnicianInventory/TechnicianInventoryImages";
import { FormikProvider, useFormik } from "formik";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import useInventory from "hooks/useInventory";
import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { defaultTechnicianInventory } from "types/inventory";
import { Tag } from "types/tag";
import { TechnicianFormSchema } from "../components/TechnicianInventory/ValidationSchema";
import { useAlert } from "../hooks/useAlert";
import keysof from "../utils/keysof";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const typeIndex = ["inventory_details", "top_case", "board", "lcd", "battery"];

const TabPanel = React.forwardRef<HTMLDivElement, TabPanelProps>((props, ref) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      ref={ref}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
});

export default function TechnicianInventoryPage() {
  const { updateInventory, inventory } = useInventory();
  const navigate = useNavigate();
  const { alertSuccess, alertError } = useAlert();
  const { enabled } = useFeatureFlag("inventory_parts");
  const [disabled, setDisabled] = useState(true);
  const [inventoryId, setInventoryId] = useState<string | undefined>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [inboundPartsMode, setInboundPartsMode] = useState<boolean>(false);
  const params = useParams();
  const [completed, setCompleted] = useState<{
    [k: string]: boolean;
  }>({});

  useEffect(() => {
    handleInitialValues();
  }, [inventory]);

  useEffect(() => {
    if (params.type) {
      const index = typeIndex.indexOf(params.type);
      if (index !== -1) {
        setTabIndex(index);
      }
    } else {
      setTabIndex(0);
    }
  }, [params.type, inventoryId]);

  const formValues = inventory || defaultTechnicianInventory;

  const filledFields = keysof(formValues).filter((key) => {
    const input = formValues[key];
    return input !== null;
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0 && inventory) {
      navigate(`/technician/${inventory.id}`);
    } else if (inventory) {
      const location = `/technician/${inventory.id}/inventory-parts/${typeIndex[newValue]}`;
      navigate(location, { replace: true });
    }
    setTabIndex(newValue);
  };

  const handleInitialValues = () => {
    const newCompleted: { [k: string]: boolean } = {};
    filledFields.map((step) => {
      newCompleted[step] = true;
      setCompleted(newCompleted);
    });
  };

  useEffect(() => {
    inventory ? setDisabled(false) : setDisabled(true);
  }, [inventory]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formValues,
    validationSchema: TechnicianFormSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      try {
        await updateInventory(values);
        alertSuccess("Changes saved successfully.");
        navigate(`/technician`);
        location.reload();
      } catch {
        alertError("There was an error saving your changes. Please try again.");
      }
    },
  });

  const { handleSubmit, handleChange, errors, handleBlur, resetForm } = formik;

  const container = useRef(null);

  useEffect(() => {
    resetForm({ ...formValues } as any);
  }, [formValues]);

  const handleNavigation = useCallback(
    _.debounce((id) => {
      if (id && id !== "") {
        navigate(`/technician/${id}`);
      } else {
        navigate("/technician");
      }
    }, 800),
    [],
  );

  useEffect(() => {
    if (inventoryId !== undefined) {
      handleNavigation(inventoryId);
    }
  }, [inventoryId]);

  return (
    <Box>
      <Grid container marginBottom={2} alignItems={"center"} spacing={2}>
        <Grid item xs={inventory ? false : true} alignSelf="flex-start">
          <TextField
            autoFocus
            disabled={inboundPartsMode}
            fullWidth
            variant={"outlined"}
            label={"Inventory ID"}
            placeholder={inventory ? inventory.id.toString() : "Scan or type ID"}
            onChange={(e) => setInventoryId(e.target.value)}
            onFocus={(e) => e.target.select()}
            value={inventoryId}
            InputProps={{
              startAdornment: (
                <InputAdornment position={"start"}>
                  <SearchIcon color={inventory ? "primary" : "disabled"} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {inventory ? (
          <>
            <Grid container item xs spacing={2}>
              <Grid item>
                <Grid container gap={1}>
                  <Typography display="inline" variant="body1">
                    ID:
                  </Typography>
                  <Typography display="inline" color="primary.main" variant="body2" noWrap>
                    {inventory.id}
                  </Typography>
                </Grid>

                <Grid container gap={1}>
                  <Typography display="inline" variant="body1">
                    Label:
                  </Typography>
                  <Typography display="inline" color="primary.main" variant="body2" noWrap>
                    {inventory.label ?? ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container gap={1}>
                  <Typography display="inline" variant="body1">
                    Location:
                  </Typography>
                  <Typography display="inline" color="primary.main" variant="body2" noWrap>
                    {inventory.warehouseLocation ?? ""}
                  </Typography>
                </Grid>

                <Grid container gap={1}>
                  <Typography display="inline" variant="body1">
                    Warehouse:
                  </Typography>
                  <Typography display="inline" color="primary.main" variant="body2" noWrap>
                    {inventory.warehouse?.name ?? ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container gap={1}>
                  <Typography display="inline" variant="body1">
                    State:
                  </Typography>
                  <Typography display="inline" color="primary.main" variant="body2" noWrap>
                    {inventory.state}
                  </Typography>
                </Grid>

                <Grid container gap={1}>
                  <Typography display="inline" variant="body1">
                    Group:
                  </Typography>
                  <Typography display="inline" color="primary.main" variant="body2" noWrap>
                    {inventory.inventoryGroup ?? ""}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container xs padding={2} justifyContent="flex-end">
                <Grid item alignSelf="center">
                  <DeviceWipeStatus refInventory={inventory} />
                </Grid>
                <Grid item>
                  <Tooltip disableInteractive title={"View the associated pickup for this item"}>
                    <IconButton href={`/pickups/${inventory.pickup.uuid}`} target={"_blank"}>
                      <ListAltIcon color={"primary"} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <InventoryChangeLocationButton refInventory={inventory} />
                </Grid>
                <Grid item>
                  <InventoryPrintLabelButton refInventory={inventory} />
                </Grid>
                <Grid item>
                  <InventoryEventHistoryButton entityId={inventory.id} eventType="inventory_events" />
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          enabled && (
            <Grid item>
              <Button
                size="large"
                variant="contained"
                startIcon={<InputIcon />}
                sx={{ px: 4 }}
                onClick={() => {
                  setInboundPartsMode((prevState) => !prevState);
                  setInventoryId("");
                }}
              >
                {inboundPartsMode ? "Back" : "Inbound Parts"}
              </Button>
            </Grid>
          )
        )}
      </Grid>

      <Box>
        {inboundPartsMode ? (
          <InventoryPartsBulkInbound />
        ) : (
          <Grid container columns={2} spacing={2}>
            <Grid item sm={2} md={1}>
              <FormikProvider value={formik}>
                <form onSubmit={handleSubmit} onChange={handleChange} onBlur={handleBlur} id="inventory">
                  <Box component={Paper} variant={"outlined"} padding={2}>
                    <Typography variant={"h6"} gutterBottom>
                      Tech Form
                    </Typography>
                    <TechForm disabled={disabled} completed={completed} setCompleted={setCompleted} />
                    <FunctionalitySwitches disabled={disabled} />

                    <Typography variant={"h6"} gutterBottom>
                      Tags
                    </Typography>
                    <Tags
                      disabled={disabled}
                      size={"small"}
                      tags={formik.values.tags || []}
                      onChange={(tags: Tag[]) => {
                        formik.setFieldValue("tags", tags);
                      }}
                    />
                  </Box>
                  <Portal container={container.current}>
                    <InventoryAttributes inventory={inventory} disabled={disabled} />
                    <Box>
                      <Typography variant={"h6"} paddingTop={2}>
                        Photos
                      </Typography>
                      {inventory ? <TechnicianInventoryImages /> : null}
                      <Grid container justifyContent="center" pt={2}>
                        <Grid item>
                          <Button
                            form="inventory"
                            fullWidth
                            disabled={Array.isArray(errors) || Object.values(errors).toString() != ""}
                            size="large"
                            type="submit"
                            variant="contained"
                            sx={{ py: 2 }}
                          >
                            Save Inventory
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Portal>
                </form>
              </FormikProvider>
            </Grid>

            <Grid item sm={2} md={1}>
              <Box component={Paper} variant={"outlined"} padding={2}>
                {enabled && inventory !== undefined && (
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabIndex}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                    >
                      {/* add an attribute to below tabs to pass a strings */}
                      <Tab icon={<InfoIcon />} label="Inventory Details" value={0} />
                      <Tab icon={<KeyboardIcon />} label="Case" value={1} />
                      <Tab icon={<MemoryIcon />} label="Logic Board" value={2} />
                      <Tab icon={<MonitorIcon />} label="LCD" value={3} />
                      <Tab icon={<BatteryChargingFullIcon />} label="Battery" value={4} />
                    </Tabs>
                  </Box>
                )}
                <TabPanel index={0} value={tabIndex} ref={container} />
                <TabPanel index={1} value={tabIndex}>
                  <InventoryPartsPanel partType={"TopCase"} parentInventory={inventory} />
                </TabPanel>
                <TabPanel index={2} value={tabIndex}>
                  <InventoryPartsPanel partType="Board" parentInventory={inventory} />
                </TabPanel>
                <TabPanel index={3} value={tabIndex}>
                  <InventoryPartsPanel partType="Lcd" parentInventory={inventory} />
                </TabPanel>
                <TabPanel index={4} value={tabIndex}>
                  <InventoryPartsPanel partType="Battery" parentInventory={inventory} />
                </TabPanel>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}
