import React from "react";
import QRScanner from "components/Scan/QRScanner";
import { Button, ButtonProps } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useIsLocal } from "hooks/useIsLocalhost";

interface ScanButtonProps extends ButtonProps {
  children: React.ReactNode;
  onScan: (value: string | undefined | null) => void;
}

export function ScanButton({ children, onScan, ...buttonProps }: ScanButtonProps) {
  const [showScan, setShowScan] = React.useState(false);
  const [scanAttempt, setScanAttempt] = React.useState(0);
  const isLocal = useIsLocal();

  return (
    <>
      <QRScanner
        key={scanAttempt}
        open={showScan}
        handleClose={() => {
          setShowScan(false);
          setScanAttempt((p) => p + 1);
        }}
        readCompleted={(inventoryId, warehouseLocation) => {
          setShowScan(false);
          setScanAttempt((p) => p + 1);
          onScan(inventoryId || warehouseLocation);
        }}
      />
      {isLocal && (
        <Button
          onClick={() => {
            const value = prompt("Scan Bypass: what value would have been scanned?");
            if (value) onScan(value);
          }}
          {...buttonProps}
        >
          Bypass Scan
        </Button>
      )}
      <Button startIcon={<QrCodeScannerIcon />} variant="contained" onClick={() => setShowScan(true)} {...buttonProps}>
        {children}
      </Button>
    </>
  );
}
