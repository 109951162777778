import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormControlLabelProps,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useMemo } from "react";

interface CameraSetupProps {
  devices: MediaDeviceInfo[];
  deviceInfos: Array<MediaDeviceInfo | null>;
  setDeviceCallback: (index: number, deviceInfo: MediaDeviceInfo | null) => void;
  setShowConfigurationPanel: Dispatch<SetStateAction<boolean>>;
}

export default function CameraSetup({
  devices,
  deviceInfos,
  setDeviceCallback,
  setShowConfigurationPanel,
}: CameraSetupProps) {
  const deviceOptions = useMemo(
    () =>
      devices.map((device: MediaDeviceInfo, key: number) => (
        <MenuItem key={key} value={device.deviceId}>
          {device.deviceId.substring(0, 4).toUpperCase()}: {device.label}
        </MenuItem>
      )),
    [devices],
  );

  const getDeviceId = (index: number) => deviceInfos[index]?.deviceId ?? "";

  const SelectDevicePosition = ({ index, props }: { index: number; props: Partial<FormControlLabelProps> }) => (
    <FormControlLabel
      {...props}
      control={
        <TextField
          select
          size="small"
          sx={{ width: 250 }}
          defaultValue=""
          onChange={(event) =>
            setDeviceCallback(index, devices.find(({ deviceId }) => deviceId === event.target.value) || null)
          }
          value={getDeviceId(index)}
        >
          {deviceOptions}
        </TextField>
      }
      label={<Typography variant="h6">{props.label}</Typography>}
      labelPlacement="top"
    />
  );

  return (
    <Box component={Paper} variant="outlined" padding={4}>
      <Stack spacing={4}>
        <Stack>
          <Typography fontSize="24px" fontWeight={600}>
            Camera Setup
          </Typography>
          <Typography fontSize="14px" fontWeight={400}>
            Configure the position of each camera. The configuration will be saved locally.
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          <SelectDevicePosition index={0} props={{ label: "Front Camera" }} />
          <SelectDevicePosition index={1} props={{ label: "Left Camera" }} />
          <SelectDevicePosition index={2} props={{ label: "Right Camera" }} />
          <SelectDevicePosition index={3} props={{ label: "Top Camera" }} />
          <SelectDevicePosition index={4} props={{ label: "Hero Camera" }} />
        </Stack>
        <Divider />
        <Stack direction="row" spacing={2} justifyContent="end">
          <Button onClick={() => setShowConfigurationPanel(false)} sx={{ paddingX: 3 }} variant="contained">
            Save Configuration
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
