import { Box, TextField, Typography, IconButton } from "@mui/material";
import { Form, Formik } from "formik";
import { theme } from "index";
import { AssetRetrieval } from "types/assetRetrieval";
import PrintIcon from "@mui/icons-material/Print";
interface AssetRetrievalRowProps {
  row: AssetRetrieval;
  submit: (assetRetrievalsUuid: string, params: { type: string; trackingNumber: string }) => void;
  update: (assetRetrievalsUuid: string, shipmentUuid: string, params: { type: string; trackingNumber: string }) => void;
}

const INSURANCE_OPTIONS_DISPLAY: { [number: number]: number } = {
  0: 0,
  10: 1000,
  20: 2000,
  30: 3000,
  40: 4000,
  50: 5000,
};

export default function AssetRetrievalRow({ row, submit, update }: AssetRetrievalRowProps) {
  return (
    <>
      <Box
        sx={{
          my: 1,
          border: "1px solid",
          borderColor: theme.palette.divider,
          mx: 4,
          borderRadius: 2,
          marginY: 4,
          background: "#FAFAFA",
        }}
      >
        <Box sx={{ display: "grid", gridTemplateColumns: "0.8fr 1fr 1fr 0.7fr", my: 3, mx: 3, columnGap: 1 }}>
          <Box>
            <Typography sx={{ opacity: 60 }} color="GrayText" textTransform="uppercase">
              to label details
            </Typography>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", my: 2, columnGap: 1 }}>
              <Box>
                <Box>
                  {row.employeeFirstName} {row.employeeLastName}
                </Box>
                <Box>{row.employeeEmail}</Box>
                <Box>{row.employeePhone}</Box>
              </Box>
              <Box>
                <Box>{row.employeeAddress}</Box>
                <Box>{row.employeeAddressTwo}</Box>
                <Box>
                  {row.employeeAddressCity}, {row.employeeAddressState} {row.employeeAddressZipCode}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ opacity: 60 }} color="GrayText" textTransform="uppercase">
              return label details
            </Typography>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", my: 2, rowGap: 2, columnGap: 1 }}>
              <Box>
                {row.returnRecipientFirstName ? (
                  <>
                    <Box>
                      {row.returnRecipientFirstName} {row.returnRecipientLastName}
                    </Box>
                    <Box>{row.returnRecipientCompany}</Box>
                  </>
                ) : (
                  <>
                    <Box>
                      {row.user
                        ? `${row.user.firstName} ${row.user.lastName}`
                        : `API order ${row.organization.name} returning to Revivn`}
                    </Box>
                    <Box>{row.user ? row.user.email : ""}</Box>
                  </>
                )}
              </Box>
              <Box>
                <Box>{row.organization.name}</Box>
                {row.location ? (
                  <>
                    <Box>{row.location.address}</Box>
                    <Box>{row.location.address2}</Box>
                    <Box>
                      {row.location.city}, {row.location.state} {row.location.zipcode}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box>{row.returnRecipientAddress}</Box>
                    <Box>{row.returnRecipientAddressTwo}</Box>
                    <Box>
                      {row.returnRecipientAddressCity}, {row.returnRecipientAddressState}{" "}
                      {row.returnRecipientAddressZipCode}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ opacity: 60 }} color="GrayText" textTransform="uppercase">
              Device details
            </Typography>
            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", my: 2, rowGap: 2, columnGap: 1 }}>
              <Box>
                <Box>
                  Insurance Value: $
                  {row.insuranceOption ? INSURANCE_OPTIONS_DISPLAY[row.insuranceOption] : row.insuranceOption}
                </Box>
              </Box>
              <Box>
                <Box>{row.manufacturer}</Box>
                <Box>{row.model}</Box>
                <Box>{row.serialNumber}</Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "grid", rowGap: 1 }}>
            <Typography sx={{ opacity: 60 }} color="GrayText">
              To - Tracking Number #:{" "}
            </Typography>
            <Box display="flex" alignItems="center">
              <Formik
                initialValues={{
                  outboundAssetRetrievalShipment: row.outboundAssetRetrievalShipment?.trackingNumber || "",
                }}
                onSubmit={(values) => {
                  const payload = {
                    type: "OutboundAssetRetrievalShipment",
                    trackingNumber: values.outboundAssetRetrievalShipment,
                  };
                  if (row.outboundAssetRetrievalShipment) {
                    update(row.uuid, row.outboundAssetRetrievalShipment.uuid, payload);
                  } else {
                    submit(row.uuid, payload);
                  }
                }}
              >
                {({ handleSubmit, getFieldProps }) => (
                  <Form>
                    <Box sx={{ display: "grid", rowGap: 1 }}>
                      <TextField
                        size="small"
                        placeholder="To Label"
                        disabled={row.state !== "order_placed"}
                        {...getFieldProps("outboundAssetRetrievalShipment")}
                        onBlur={() => handleSubmit()}
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
              <IconButton
                aria-label="print"
                disabled={!row.outboundAssetRetrievalShipment?.shippingLabelPdfUrl}
                sx={{ my: 1 }}
                color="primary"
                onClick={() => {
                  window.open(row.outboundAssetRetrievalShipment.shippingLabelPdfUrl, "_blank");
                }}
              >
                <PrintIcon />
              </IconButton>
            </Box>

            <Typography sx={{ opacity: 60 }} color="GrayText">
              Return - Tracking Number #:{" "}
            </Typography>
            <Box display="flex" alignItems="center">
              <Formik
                initialValues={{
                  inboundAssetRetrievalShipment: row.inboundAssetRetrievalShipment?.trackingNumber || "",
                }}
                onSubmit={(values) => {
                  const payload = {
                    type: "InboundAssetRetrievalShipment",
                    trackingNumber: values.inboundAssetRetrievalShipment,
                  };
                  if (row.inboundAssetRetrievalShipment) {
                    update(row.uuid, row.inboundAssetRetrievalShipment.uuid, payload);
                  } else {
                    submit(row.uuid, payload);
                  }
                }}
              >
                {({ handleSubmit, getFieldProps }) => (
                  <Form>
                    <Box sx={{ display: "grid", rowGap: 1 }}>
                      <TextField
                        size="small"
                        placeholder="Return Label"
                        disabled={row.state !== "order_placed"}
                        {...getFieldProps("inboundAssetRetrievalShipment")}
                        onBlur={() => handleSubmit()}
                      />
                    </Box>
                  </Form>
                )}
              </Formik>
              <IconButton
                aria-label="print"
                color="primary"
                disabled={!row.inboundAssetRetrievalShipment?.shippingLabelPdfUrl}
                onClick={() => {
                  window.open(row.inboundAssetRetrievalShipment.shippingLabelPdfUrl, "_blank");
                }}
              >
                <PrintIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box margin={2}>
          {row.orderAcceptedAt && (
            <Typography>
              Order accepted at <b>{new Date(Date.parse(row.orderAcceptedAt)).toLocaleString()}</b>
            </Typography>
          )}
          {row.packageOutToEmployeeAt && (
            <Typography>
              Package shipped to employee at <b>{new Date(Date.parse(row.packageOutToEmployeeAt)).toLocaleString()}</b>
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}
