import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { PartnerInventory } from "types/partnerInventory";
import useBackgroundTask from "./useBackgroundTask";

export default function usePartnerInventories() {
  const params = useParams();
  const [partnerInventories, setPartnerInventories] = useState<PartnerInventory[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  const getPartnerInventories = useCallback(() => {
    api.get(`/pickups/${params.pickupUuid}/partner_inventories${search}`).then(
      ({
        data: {
          partnerInventories: fetchedPartnerInventories,
          meta: { totalPages: fetchedTotalPages, count: fetchedCount },
        },
      }) => {
        setPartnerInventories(fetchedPartnerInventories);
        setTotalPages(fetchedTotalPages);
        setCount(fetchedCount);
      },
    );
  }, [params.pickupUuid, search]);

  const backgroundTask = useBackgroundTask;

  const uploadPartnerInventory = useCallback(
    (imageKey: string) => {
      return backgroundTask(
        api.post<{ jobId: string }>("/partner_inventory_uploads", {
          partnerInventoryUpload: { file: imageKey, pickupUuid: params.pickupUuid },
        }),
      ).then(() => {
        getPartnerInventories();
      });
    },
    [backgroundTask, getPartnerInventories, params.pickupUuid],
  );

  useEffect(() => {
    getPartnerInventories();
  }, [getPartnerInventories, search]);

  return {
    uploadPartnerInventory,
    partnerInventories,
    count,
    totalPages,
  };
}
