import {
  DataGridPro,
  GridColDef,
  getGridStringOperators,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { Link } from "react-router-dom";
import { Box, styled, Paper, LinearProgress, Stack } from "@mui/material";
import useServerSideDataGridParams from "hooks/useServerSideDataGridParams";
import useLogisticsPartners from "hooks/useLogisticsPartners";
import { LogisticsPartner } from "types/logisticsPartner";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  height: "80vh",
  border: "none",
  backgroundColor: "#FFFFFF",
  "& .MuiDataGrid-row:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

export default function PartnersDataGrid() {
  const defaultPageSize = 25;
  const usePartnersState = useLogisticsPartners(defaultPageSize);
  const { logisticsPartners, count, isLoading } = usePartnersState;
  const apiRef = useGridApiRef();

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 300,
      sortable: false,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
      renderCell: (params: GridRenderCellParams<LogisticsPartner>) => {
        return <Link to={`/logistics/partners/${params.row.uuid}`}>{params.row.name}</Link>;
      },
    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      width: 200,
      filterOperators: getGridStringOperators().filter((op) => op.value === "contains"),
    },
    {
      field: "googleDriveLink",
      headerName: "Google Drive",
      sortable: false,
      filterable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams<LogisticsPartner>) => {
        return (
          <a href={params.row.googleDriveLink} target="_blank">
            {params.row.googleDriveLink ? "View Drive" : ""}
          </a>
        );
      },
    },
  ];

  const pageState = useServerSideDataGridParams(logisticsPartners);

  return (
    <Box sx={{ width: "100%", height: "80vh" }}>
      <Paper elevation={2} sx={{ mt: 2, p: 1 }}>
        <StyledDataGrid
          rows={logisticsPartners}
          columns={columns}
          {...pageState}
          pageSize={pageState.pageSize || defaultPageSize}
          rowsPerPageOptions={[5, 10, 25, 100]}
          rowCount={count}
          filterMode="server"
          apiRef={apiRef}
          disableSelectionOnClick
          checkboxSelection={false}
          checkboxSelectionVisibleOnly={false}
          loading={isLoading}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: CustomToolbar,
            NoResultsOverlay: () => (
              <Stack height="100%" alignItems="center" justifyContent="center">
                No Partners matching your filters
              </Stack>
            ),
          }}
          componentsProps={{ filterPanel: { sx: { minHeight: "200px" } } }}
        />
      </Paper>
    </Box>
  );
}
