import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { LogisticsPartner } from "types/logisticsPartner";

export default function useItads(defaultPageSize?: number) {
  const location = useLocation();
  const [itads, setItads] = useState<LogisticsPartner[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  const getItads = useCallback(
    (query?: string) => {
      const searchHash = new URLSearchParams(location.search);
      if (!searchHash.get("page[size]") && defaultPageSize) {
        searchHash.append("page[size]", String(defaultPageSize));
      }
      api.get(`/itads?${searchHash.toString()}${query ? "q=" + query : ""}`).then(
        ({
          data: {
            itads: fetchedItads,
            meta: { totalPages: fetchedTotalPages, count: fetchedCount },
          },
        }) => {
          setItads(fetchedItads);
          setTotalPages(fetchedTotalPages);
          setCount(fetchedCount);
        },
      );
    },
    [defaultPageSize, location.search],
  );

  useEffect(() => {
    getItads();
  }, [getItads, search]);

  return {
    itads,
    getItads,
    totalPages,
    count,
  };
}
