import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { LogisticsPartner } from "types/logisticsPartner";

export default function useAgents(defaultPageSize?: number) {
  const location = useLocation();
  const [mainAgents, setMainAgents] = useState<LogisticsPartner[]>([]);
  const [subAgents, setSubAgents] = useState<LogisticsPartner[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  const getAgents = useCallback(
    (query?: string, type?: string) => {
      const searchHash = new URLSearchParams(location.search);
      if (!searchHash.get("page[size]") && defaultPageSize) {
        searchHash.append("page[size]", String(defaultPageSize));
      }
      api.get(`/agents?${searchHash.toString()}${query ? "q=" + query : ""}`).then(
        ({
          data: {
            agents,
            meta: { totalPages: total, count: totalCount },
          },
        }) => {
          if (type === "subAgents") {
            setSubAgents(agents);
          } else if (type === "mainAgents") {
            setMainAgents(agents);
          } else {
            setSubAgents(agents);
            setMainAgents(agents);
          }
          setTotalPages(total);
          setCount(totalCount);
        },
      );
    },
    [defaultPageSize, location.search],
  );

  useEffect(() => {
    getAgents();
  }, [getAgents, search]);

  return {
    mainAgents,
    subAgents,
    getAgents,
    totalPages,
    count,
  };
}
