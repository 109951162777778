import { forwardRef } from "react";
import { Box } from "@mui/material";
import PartsQRCode from "components/Scan/PartsQRCode";
import { InventoryPart } from "types/inventoryPart";

const PartsPrintableQRCodes = forwardRef<HTMLDivElement, { selectedInventoryParts: InventoryPart[] }>(
  ({ selectedInventoryParts }, ref) => (
    <Box
      ref={ref}
      sx={{
        margin: 0,
        display: "none",
        "@media print": {
          display: "block",
        },
      }}
    >
      {selectedInventoryParts.map((inventoryPart) => {
        return (
          <Box
            key={inventoryPart.id}
            padding={1}
            sx={{
              "@media print": {
                height: "100mm",
                width: "62mm",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <PartsQRCode part={inventoryPart} />
          </Box>
        );
      })}
    </Box>
  ),
);

export default PartsPrintableQRCodes;
