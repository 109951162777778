import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { Pickup } from "types/pickup";

export default function usePickups() {
  const [pickups, setPickups] = useState<Pickup[]>([]);
  const [organizationPickups, setOrganizationPickups] = useState<Pickup[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  const getPickupsByOrganization = (organization: string) => {
    api.get(`/pickups?q=${organization}`).then(
      ({
        data: {
          pickups: organizationPickupsData,
          meta: { totalPages: orgTotalPages, count: orgCount },
        },
      }) => {
        setOrganizationPickups(organizationPickupsData);
        setTotalPages(orgTotalPages);
        setCount(orgCount);
      },
    );
  };

  useEffect(() => {
    api.get(`/pickups${search}`).then(
      ({
        data: {
          pickups: fetchedPickups,
          meta: { totalPages: fetchedTotalPages, count: fetchedCount },
        },
      }) => {
        setPickups(fetchedPickups);
        setTotalPages(fetchedTotalPages);
        setCount(fetchedCount);
      },
    );
  }, [search]);

  return {
    pickups,
    organizationPickups,
    getPickupsByOrganization,
    totalPages,
    count,
  };
}
