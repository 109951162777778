import React, { useMemo, useState } from "react";
import { InventoryImage } from "types/inventory";
import { PackInventoryLayout } from "components/Picklist/CurrentBatch/Packing/PackInventoryLayout";
import { TestInventoryStep } from "components/Picklist/CurrentBatch/Packing/Steps/TestInventoryStep";
import { TestsFailedStep } from "components/Picklist/CurrentBatch/Packing/Steps/TestsFailedStep";
import { PrintShippingLabelStep } from "components/Picklist/CurrentBatch/Packing/Steps/PrintShippingLabelStep";
import { CapturePhotoStep } from "components/Picklist/CurrentBatch/Packing/Steps/CapturePhotoStep";
import { CompletePackingStep } from "components/Picklist/CurrentBatch/Packing/Steps/CompletePackingStep";
import { OrderItem, useCurrentFulfillmentBatch } from "hooks/useCurrentFulfillmentBatch";

export enum Step {
  TestFailed = -1,
  TestInventory,
  PrintShippingLabel,
  CapturePhoto,
  Complete,
}

interface PackInventoryWizardProps {
  inventory: OrderItem;
  onInventoryComplete: () => void;
}

export function PackInventoryWizard({ inventory, onInventoryComplete }: PackInventoryWizardProps) {
  const [image, setImage] = useState<InventoryImage | null>(null);
  const [activeStep, setActiveStep] = useState(Step.TestInventory);
  const { mutate: refreshBatch } = useCurrentFulfillmentBatch();

  const stepComponent = useMemo(() => {
    switch (activeStep) {
      case Step.TestInventory:
        return (
          <TestInventoryStep
            onTestsPassed={() => setActiveStep(Step.PrintShippingLabel)}
            onTestsFailed={() => {
              setActiveStep(Step.TestFailed);
            }}
          />
        );
      case Step.TestFailed:
        return <TestsFailedStep onCancel={() => setActiveStep(Step.TestInventory)} onComplete={onInventoryComplete} />;
      case Step.PrintShippingLabel:
        return (
          <PrintShippingLabelStep
            inventory={inventory}
            onClick={() => {
              refreshBatch();
              setActiveStep(Step.CapturePhoto);
            }}
          />
        );
      case Step.CapturePhoto:
        return (
          <CapturePhotoStep
            inventory={inventory}
            onImageCaptured={(img) => {
              setImage(img);
              setActiveStep(Step.Complete);
            }}
          />
        );
      case Step.Complete:
        return (
          <CompletePackingStep
            inventory={inventory}
            onImageRecapture={(img) => {
              setImage(img);
            }}
            onSuccess={onInventoryComplete}
          />
        );
    }
  }, [activeStep, inventory, onInventoryComplete, refreshBatch]);

  return (
    <PackInventoryLayout activeStep={activeStep} inventory={inventory} image={image}>
      {stepComponent}
    </PackInventoryLayout>
  );
}
