import { api } from "services/api.service";
import { useAlert } from "./useAlert";

export default function useAssetRetrievalShipments() {
  const { alertSuccess, alertError } = useAlert();

  const createAssetRetrievalShipments = (
    assetRetrievalsUuid: string,
    params: { type: string; trackingNumber: string },
  ) => {
    return api
      .post(`/asset_retrievals/${assetRetrievalsUuid}/asset_retrieval_shipments`, params)
      .catch(() => {
        alertError("Something went wrong, please try again.");
      })
      .finally(() => {
        alertSuccess("Tracking number was successfully saved.");
      });
  };

  const updateAssetRetrievalShipments = (
    assetRetrievalsUuid: string,
    shipmentUuid: string,
    params: { type: string; trackingNumber: string },
  ) => {
    return api
      .put(`/asset_retrievals/${assetRetrievalsUuid}/asset_retrieval_shipments/${shipmentUuid}`, params)
      .catch(() => {
        alertError("Something went wrong, please try again.");
      })
      .finally(() => {
        alertSuccess("Tracking number was successfully updated.");
      });
  };

  return {
    createAssetRetrievalShipments,
    updateAssetRetrievalShipments,
  };
}
