import { VideocamOff } from "@mui/icons-material";
import { Box, Card, CircularProgress, Stack, Typography } from "@mui/material";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";

interface WebcamComponentProps {
  device: MediaDeviceInfo | null;
}

const WebcamInitializingPanel = () => (
  <Stack display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
    <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
      <CircularProgress size={30} />
      <Typography variant="body1">Initializing camera...</Typography>
    </Stack>
    <Typography color="text.secondary" variant="caption">
      Please wait while we access the camera.
    </Typography>
  </Stack>
);

const WebcamErrorPanel = ({ error }: { error: string | DOMException }) => (
  <Stack display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={1}>
    <Stack display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
      <VideocamOff fontSize="large" color="disabled" />
      <Typography variant="body1">MediaStream error</Typography>
    </Stack>
    <Typography color="text.secondary" variant="caption">
      {error.toString()}
    </Typography>
  </Stack>
);

export const WebcamComponent = forwardRef<Webcam, WebcamComponentProps>(function WebcamComponent({ device }, ref) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | DOMException>("");
  const [retryCount, setRetryCount] = useState(0);
  const retryTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const noError = error == "";

  const handleRetry = useCallback(() => {
    retryTimeoutRef.current = setTimeout(() => {
      setLoading(true);
      setError("");
      setRetryCount((count) => count + 1);
    }, retryCount * 2000);
  }, [retryCount]);

  const onUserMedia = useCallback(() => {
    setLoading(false);
    setError("");
    setRetryCount(0);
  }, []);

  const onUserMediaError = useCallback(
    (err: string | DOMException) => {
      setLoading(false);
      setError(err);
      console.warn("Webcam error:", err);
      if (retryCount < 5) handleRetry();
    },
    [handleRetry, retryCount],
  );

  // Destroy the retry timeout when the component is unmounted
  useEffect(() => {
    const timeoutId = retryTimeoutRef.current;
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  return (
    <Box
      component={Card}
      variant="outlined"
      sx={{
        maxWidth: 480,
        aspectRatio: "16/9",
        borderRadius: 2,
        borderWidth: 2,
      }}
    >
      {device && noError && (
        <Webcam
          ref={ref}
          forceScreenshotSourceSize
          onUserMedia={onUserMedia}
          onUserMediaError={onUserMediaError}
          screenshotFormat="image/jpeg"
          screenshotQuality={1}
          videoConstraints={{
            deviceId: { exact: device.deviceId },
            width: { ideal: 3840 },
            height: { ideal: 2160 },
            frameRate: { ideal: 15 },
          }}
          style={{ width: "100%", height: "100%" }}
        />
      )}
      {loading && <WebcamInitializingPanel />}
      {error && <WebcamErrorPanel error={error} />}
    </Box>
  );
});
