import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { BenefitingOrganization } from "types/benefitingOrganization";
import { useAlert } from "./useAlert";

export default function useBenefitingOrganizations({
  search: argSearch,
  returnAllResults = false,
}: { search?: string; returnAllResults?: boolean } = {}) {
  const [benefitingOrganizations, setBenefitingOrganizations] = useState<BenefitingOrganization[]>([]);
  const [benefitingOrganization, setBenefitingOrganization] = useState<BenefitingOrganization>();
  const [key, setKey] = useState<string | null>(null);
  const [meta, setMeta] = useState<{ totalPages: number; count: number }>({ totalPages: 0, count: 0 });
  const params = useParams();
  const { search = argSearch } = useLocation();
  const { alertError } = useAlert();

  const createBenefitingOrganization = (benefiting_org: BenefitingOrganization) => {
    const { name, websiteUrl } = benefiting_org;
    api
      .post(`/benefiting_organizations`, {
        benefitingOrganization: { name, websiteUrl, logo: key },
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  };

  const getBenefitingOrganization = useCallback((uuid: string) => {
    api.get(`/benefiting_organizations/${uuid}`).then(({ data }) => {
      setBenefitingOrganization(data);
    });
  }, []);

  const getBenefitingOrganizations = useCallback(() => {
    api
      .get<{
        benefitingOrganizations: BenefitingOrganization[];
        meta: { totalPages: number; count: number };
      }>(`/benefiting_organizations/${returnAllResults ? "?all_data=true" : search}`)
      .then(({ data }) => {
        setBenefitingOrganizations(data.benefitingOrganizations);
        setMeta(data.meta);
      });
  }, [returnAllResults, search]);

  const updateBenefitingOrganization = (benefiting_org: BenefitingOrganization) => {
    const { name, websiteUrl } = benefiting_org;
    api
      .put(`benefiting_organizations/${benefiting_org.uuid}`, {
        benefitingOrganization: { name, websiteUrl, logo: key },
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  };

  const updateBenefitingOrganizationStatus = (uuid: string, state: string) => {
    api
      .put(`benefiting_organizations/${uuid}`, {
        benefitingOrganization: { state: state },
      })
      .then(() => {
        getBenefitingOrganizations();
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  };

  const deleteLogo = (uuid: string) => {
    api
      .delete(`benefiting_organizations/logo/${uuid}`)
      .then(() => {
        getBenefitingOrganizations();
      })
      .catch(() => {
        alertError("Something went wrong, please try again.");
      });
  };

  const deleteBenefitingOrganization = useCallback(
    (uuid: string) => {
      api
        .delete(`benefiting_organizations/${uuid}`)
        .then(() => {
          getBenefitingOrganizations();
        })
        .catch(() => {
          alertError("Something went wrong, please try again.");
        });
    },
    [alertError, getBenefitingOrganizations],
  );

  useEffect(() => {
    if (params.benefitingOrganizationUuid) {
      getBenefitingOrganization(params.benefitingOrganizationUuid);
    }
  }, [getBenefitingOrganization, params.benefitingOrganizationUuid]);

  useEffect(() => {
    getBenefitingOrganizations();
  }, [getBenefitingOrganizations, search]);

  return {
    benefitingOrganization,
    benefitingOrganizations,
    createBenefitingOrganization,
    updateBenefitingOrganization,
    deleteBenefitingOrganization,
    getBenefitingOrganization,
    setKey,
    key,
    deleteLogo,
    updateBenefitingOrganizationStatus,
    meta,
  };
}
