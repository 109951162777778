import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { api } from "services/api.service";
import { LogisticsPartner } from "types/logisticsPartner";

export default function useFreighters(defaultPageSize?: number) {
  const location = useLocation();
  const [mainFreighters, setMainFreighters] = useState<LogisticsPartner[]>([]);
  const [subFreighters, setSubFreighters] = useState<LogisticsPartner[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const { search } = useLocation();

  const getFreighters = useCallback(
    (query?: string, type?: string) => {
      const searchHash = new URLSearchParams(location.search);
      if (!searchHash.get("page[size]") && defaultPageSize) {
        searchHash.append("page[size]", String(defaultPageSize));
      }
      api.get(`/freighters?${searchHash.toString()}${query ? "q=" + query : ""}`).then(
        ({
          data: {
            freighters,
            meta: { totalPages: fetchedTotalPages, count: fetchedCount },
          },
        }) => {
          if (type === "subFreighters") {
            setSubFreighters(freighters);
          } else if (type === "mainFreighters") {
            setMainFreighters(freighters);
          } else {
            setSubFreighters(freighters);
            setMainFreighters(freighters);
          }
          setTotalPages(fetchedTotalPages);
          setCount(fetchedCount);
        },
      );
    },
    [defaultPageSize, location.search],
  );

  useEffect(() => {
    getFreighters();
  }, [getFreighters, search]);

  return {
    mainFreighters,
    subFreighters,
    getFreighters,
    totalPages,
    count,
  };
}
