import { useCallback, useEffect, useState } from "react";
import { api } from "services/api.service";
import { InventoryPart } from "types/inventoryPart";
import { useAlert } from "./useAlert";
import { useParams } from "react-router-dom";

/** Inventory Parts Hook. */
export default function useInventoryParts() {
  const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);
  const [inventoryPart, setInventoryPart] = useState<InventoryPart>();
  const [inventoryParts, setInventoryParts] = useState<Array<InventoryPart>>([]);
  const [inventoryPartIds, setInventoryPartIds] = useState<Array<number>>([]);
  const { alertSuccess, alertError } = useAlert();
  const params = useParams();

  useEffect(() => {
    if (params.type) {
      setAwaitingResponse(true);
      api
        .get(`/inventories/${params.inventoryId}/inventory_parts/${params.type}`)
        .then(({ data }) => {
          if (data) {
            setInventoryPart(data);
          }
        })
        .finally(() => setAwaitingResponse(false));
    }
  }, [params.inventoryId, params.type]);

  const getInventoryPartsById = (id: string) => {
    setAwaitingResponse(true);
    return api
      .get(`/inventory_parts/${id}/compatible_part/${params.type}/?inventory_id=${params.inventoryId}`)
      .then(({ data }) => {
        return data ? data : null;
      })
      .catch(({ response }) => {
        if (response.data.error.message) {
          alertError("The inventory part ID you entered does not exist.");
        } else {
          alertError(response.data.error);
        }
      })
      .finally(() => setAwaitingResponse(false));
  };

  /** Returns an array of compatible inventory parts with the matching type. */
  const getInventoryParts = () => {
    setAwaitingResponse(true);
    return api
      .get(`inventory_parts/compatible_parts/${params.type}/?inventory_id=${params.inventoryId}`)
      .then(({ data }) => {
        return data.inventoryParts;
      })
      .catch(() => {
        alertError("There was an error fetching inventory parts.");
      })
      .finally(() => setAwaitingResponse(false));
  };

  /** Create a single inventory part. POSTs to `/inventories/:inventory_id/inventory_parts`. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createInventoryPart = async (props: any) => {
    const {
      type,
      apn,
      model,
      cosmeticGrade,
      comments,
      warehouseLocation,
      logic,
      power,
      keyboard,
      trackpad,
      speaker,
      microphone,
      ioBoard,
      fans,
      battery,
      ports,
      display,
      webcam,
    } = props;

    setAwaitingResponse(true);
    return api
      .post(`/inventories/${params.inventoryId}/inventory_parts`, {
        inventoryPart: {
          type,
          apn,
          model,
          cosmeticGrade,
          comments,
          warehouseLocation,
          logic,
          power,
          keyboard,
          trackpad,
          speaker,
          microphone,
          ioBoard,
          fans,
          battery,
          ports,
          display,
          webcam,
        },
      })
      .then(({ data }) => {
        setInventoryPart(data);
        alertSuccess("Inventory part saved successfully.");
      })
      .catch(() => alertError("There was an error creating this inventory part."))
      .finally(() => setAwaitingResponse(false));
  };

  /** Creates multiple inventory parts without an associated `parentInventoryId`. POSTs to `/bulk_inventory_parts`. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bulkCreateInventoryParts = (props: any) => {
    const {
      quantity,
      color,
      type,
      apn,
      model,
      cosmeticGrade,
      comments,
      warehouseLocation,
      logicFunctional,
      power,
      keyboard,
      trackpad,
      speaker,
      microphone,
      ioBoard,
      fans,
      battery,
      ports,
      display,
      webcam,
    } = props;
    setAwaitingResponse(true);
    return api
      .post(`/inventories/bulk_inventory_parts`, {
        inventoryPart: {
          quantity,
          color,
          type,
          apn,
          model,
          cosmeticGrade,
          comments,
          warehouseLocation,
          logicFunctional,
          power,
          keyboard,
          trackpad,
          speaker,
          microphone,
          ioBoard,
          fans,
          battery,
          ports,
          display,
          webcam,
        },
      })
      .then(({ data }) => {
        setInventoryPartIds(data.inventoryPartIds);
        alertSuccess("Inventory parts saved successfully.");
      })
      .catch(() => alertError("There was an error creating these inventory parts."))
      .finally(() => setAwaitingResponse(false));
  };

  const updateInventoryPart = useCallback(
    async (part: InventoryPart) => {
      return api
        .patch(`/inventories/${params.inventoryId}/inventory_parts/${part.id}`, {
          inventoryPart: part,
        })
        .then(({ data }) => {
          setInventoryPart(data);
          alertSuccess("Inventory part saved successfully.");
        })
        .catch(() => alertError("There was an error saving this inventory part."))
        .finally(() => setAwaitingResponse(false));
    },
    [alertError, alertSuccess, params.inventoryId],
  );

  /** Replaces an inventory part by setting `currentInventoryId` to the current Inventory ID. */
  const replaceInventoryPart = async (part: InventoryPart) => {
    setAwaitingResponse(true);
    api
      .post(`/inventories/${params.inventoryId}/inventory_parts/${part.id}/replace_inventory_part`, {
        id: part.id,
        inventoryId: params.inventoryId,
      })
      .then(() => alertSuccess("Inventory part replaced successfully."))
      .catch(() => alertError("Failed to replace inventory part."))
      .finally(() => setAwaitingResponse(false));
  };

  const markInventoryPartPrinted = useCallback(() => {
    if (inventoryPart) {
      updateInventoryPart({
        ...inventoryPart,
        printedAt: new Date().toUTCString(),
        label: `${inventoryPart.parentInventoryId} - ${inventoryPart.partId}`,
      });
    }
  }, [inventoryPart, updateInventoryPart]);

  return {
    inventoryPart,
    setInventoryPart,
    inventoryParts,
    inventoryPartIds,
    setInventoryParts,
    createInventoryPart,
    updateInventoryPart,
    bulkCreateInventoryParts,
    getInventoryParts,
    replaceInventoryPart,
    markInventoryPartPrinted,
    awaitingResponse,
    getInventoryPartsById,
  };
}
