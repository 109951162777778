import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "services/api.service";
import { defaultPickup, Pickup } from "types/pickup";
import { useAlert } from "./useAlert";

export default function usePickup() {
  const params = useParams();

  const [pickup, setPickup] = useState<Pickup>(defaultPickup);
  const { alertSuccess, alertError } = useAlert();
  const navigate = useNavigate();

  const getPickup = useCallback(() => {
    api.get(`/pickups/${params.pickupId || params.pickupUuid}`).then(({ data }) => {
      setPickup(data);
    });
  }, [params.pickupId, params.pickupUuid]);

  const updatePickup = (props: Pickup) => {
    const { softthinksMode, ewasteInLbs } = props;
    return api
      .put(`/pickups/${params.pickupId || props.uuid}`, {
        pickup: {
          softthinksMode,
          ewasteInLbs,
        },
      })
      .then(() => {
        alertSuccess(`Successfully updated the pickup`);
      })
      .catch(() => {
        alertError("There was an error, please try again");
      });
  };

  const createPickup = (newPickup: Pickup) => {
    return api
      .post("/pickups", { newPickup })
      .then(({ data }) => {
        navigate(`/logistics/pickups/${data.uuid}/edit`);
      })
      .catch(() => {
        alertError("There was an error, please try again");
      });
  };

  const irReady = (pickupUuid: string) => {
    return api
      .put(`/pickups/${pickupUuid}/ir_ready`)
      .then(() => {
        getPickup();
        alertSuccess(`Successfully marked as IR Ready`);
      })
      .catch(({ response }) => {
        const error = response.data.errors;
        if (error.state) alertError(error.state[0]);
        else if (error.no_inventories) alertError(error.no_inventories);
        else alertError(Object.keys(error).length !== 0 ? error : "There was an error, please try again");
      });
  };

  const frReady = (pickupUuid: string) => {
    return api
      .put(`/pickups/${pickupUuid}/fr_ready`)
      .then(() => {
        getPickup();
        alertSuccess(`Successfully marked as FR Ready`);
      })
      .catch((error) => {
        alertError(
          error.response?.data?.errors
            ? JSON.stringify(error.response?.data?.errors, undefined, 4)
            : "There was an error, please try again",
        );
      });
  };

  const forceFrReady = (pickupUuid: string) => {
    return api
      .put(`/pickups/${pickupUuid}/force_fr_ready`)
      .then(() => {
        getPickup();
        alertSuccess(`Successfully marked as FR Ready`);
      })
      .catch(() => {
        alertError("There was an error, please try again");
      });
  };

  const fetchPickupByShippingLabel = (shippingLabel: string) => {
    return api.get(`/pickups/virtual_pickup?tracking_number=${shippingLabel}`);
  };

  useEffect(() => {
    if (params.pickupId || params.pickupUuid) {
      getPickup();
    }
  }, [getPickup, params.pickupId, params.pickupUuid]);

  return {
    irReady,
    frReady,
    forceFrReady,
    pickup,
    updatePickup,
    createPickup,
    fetchPickupByShippingLabel,
  };
}
