import useSWR from "swr";
import { fetcher, api } from "services/api.service";

export type Pricing = {
  priceableType: "AssetRetrievalInvoice";
  discountPercentage: number;
};

export default function usePricing(organizationId: string) {
  const { data, error, isLoading, mutate } = useSWR<{ pricings: Pricing[] }>(
    `organizations/${organizationId}/pricings`,
    fetcher,
  );

  const updatePricing = async (pricing: Pricing) => {
    await api
      .put(`/organizations/${organizationId}/pricings/${pricing.priceableType}`, {
        pricing,
      })
      .finally(() => {
        mutate();
      });
  };

  return {
    pricing: data,
    isLoading,
    isError: error,
    refetch: mutate,
    updatePricing,
  };
}
